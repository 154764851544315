import { observable, computed, action, decorate, ObservableSet } from "mobx";
import api from '../utils/api';
import storage from "../utils/storage";


class WordStore {
    words = [];
    stored = [];
    toggleToast = false;
    toastText = '';
    word_family = {};
    family = [];
    loading = false;
    searchResult = [];
    selectedWord = '';
    duden = [];
    search = [];
    wiki = [];
    google = {};
    arabdict = [];
    my_examples = {};
    yandex = "";
    word_count = 0;
    settings = [];
    statistics = {};
    definition = [];
    store = {
        text:"",
        word:"",
        translation: [],
        examples: [],
        postion: "",
        pronounciation: "",
        arabic_pronounciation: "",
        sound: "",
        synome: [],
        rythem: "",
        origin: "",
        phonetik: [],
        hyphenation:[],
        etymologie: "",
        bedeutungsgleich: "",
        antonyme: [],
        dialects: [],
        phraseologismen: [],
        paromien: [],
        signifikante: [],
        derivate: [],
        grammatische:[],
        dialekte: [],
    };
    definition = [];

    async getWords(count=0) {
        this.setLoading()     
        const response = await api.getWords(storage.value())
        this.words = response.data.data;
        this.unsetLoading();
    }
    
    async getWordFamily(word) {
        try {
            this.setLoading()
            const response = await api.getFamily(word);
            this.family = response.data;
            this.word_family.detail = this.family.detail
            this.unsetLoading()
        }
        catch(e) {
            this.unsetLoading()
            this.toggleNote(e.message);
        }
    }
    
    async setDuden(word) {
        try {
            this.setLoading()
            const response = await api.getDuden(word);
            this.duden = response.data;
            this.unsetLoading()
        }
        catch(e) {
            this.unsetLoading()
            this.toggleNote(e.message);
        }
    }


    async setWiki(word) {
        try {
            this.setLoading()
            const response = await api.getWiki(word);
            this.wiki = response.data;
            this.unsetLoading()
        }
        catch(e) {
            this.unsetLoading()
            this.toggleNote(e.message);
        }
    }
    

    async loadMoreFamily(count) {
        try {
            this.setLoading()
            const result = this.family.detail.slice(0, count);
            this.word_family.detail = result;
            this.unsetLoading()
        }
        catch(e) {
            this.unsetLoading()
            this.toggleNote(e.message);
        }
    }

    async translateGoogle(word) {
        try {
            this.setLoading()
            const result = await api.getGoogle(word);
            this.google = result.data
            await this.translateYandex(word)
            this.unsetLoading()   
        }
        catch(e) {
            this.unsetLoading()
            this.toggleNote(e.message);
        }
    }

    async getArabDict(word) {
        try {
            this.setLoading()
            const result = await api.getDict(word);
            this.arabdict = result.data
            this.unsetLoading()   
        }
        catch(e) {
            this.unsetLoading()
            this.toggleNote(e.message);
        }
    }

    async showMore() {
        try {
            this.setLoading()
            let count = parseInt(storage.value());
            const result = await api.getWords(count);
            this.words = [...this.words, ...result.data.data]
            storage.update();
            this.unsetLoading()
        }
        catch(e) {
            this.unsetLoading()
            this.toggleNote(e.message);
        }
    }
    
    async getExamples(word) {
        try {
            this.setLoading()
            const response = await api.getExamples(word)
            this.my_examples = response.data
            this.unsetLoading()
        }
        catch(e) {
            this.unsetLoading()
            this.toggleNote(e.message);
        }
        
    }

    async fetchSettings() {
        try {
            this.setLoading()
            const response = await api.fetchSettings()
            this.settings = response.data.settings
            this.unsetLoading()
        }
        catch(e) {
            this.unsetLoading()
            this.toggleNote(e.message);
        }
    }
    
    async useSearch(word) {
        try {
            const response = await api.useSearch(word);
            this.search = response.data.data;
        }
        catch(e) {
            this.unsetLoading()
            this.toggleNote(e.message);
        }
    }

    async translateYandex(word) {
        try {
            const response = await api.getYandex(word)
            this.yandex = response.data.data.text
        }
        catch(e) {
            this.unsetLoading()
            this.toggleNote(e.message);
        }
    }

    async getStatistics() {
        try {
            this.setLoading()
            const families = await api.statsFamilies();
            const members = await api.statsMembers();
            const {results, count} = families.data;
            this.statistics['families'] = {results, count};
            this.statistics['members'] = {results: members.data.results, count: members.data.count}
            this.unsetLoading()
        }
        catch(e) {
            this.unsetLoading()
            this.toggleNote(e.message);
        }
    }

    async commit() {
        try {
            this.setLoading()
            await api.saveWord(this.store);
            this.unsetLoading()
        }
        catch(e) {
            this.unsetLoading()
            this.toggleNote(e.message);
        }
    }

    async saveFamily(body) {
        try {
            return await api.storeFamily(body);
        }
        catch(e) {
            this.unsetLoading()
            this.toggleNote(e.message);
        }
    }

    async getDefinition() {
        try {
            this.setLoading()
            const response = await api.getDefinition(this.selectedWord);
            this.definition = response.data;
            this.unsetLoading()
        }
        catch(e) {
            this.unsetLoading()
            this.toggleNote(e.message);
        }
    }


    addOwnMemberToFamily = (word, ratio) => {
        if(word.length > 0) {
            const item = {word, id: Math.random(), ratio: parseInt(ratio)};
            this.word_family = {detail: [item, ...this.word_family.detail]};
            this.toggleNote('Word Added ...')
        }else {
            this.toggleNote('Please Select a word and get its famliy first ...')
        }
    }


    async getStored() {
        try {
            if(this.selectedWord.length > 0 && this.family.origin) {
                const response = await api.getFamilyByWord(this.family.origin);
                if(response.data.results) {
                    this.stored = response.data.results;
                }else {
                    this.stored = []
                }
            }
            return;
        }
        catch(e) {
            this.unsetLoading()
            this.toggleNote(e.message);
        }
    }

    setStore(key, value) {
        this.store[key] = value;
    }
    toggleNote(data) {
        this.toggleToast = true;
        this.toastText = data;
        setTimeout(() => {
            this.toggleToast = false;
        }, 3000);
    }
    setSettings(data) {
        this.settings = data.settings; 
    }

    setSlectedWord(word) {
        this.selectedWord = word;
    }

    setLoading() {
        this.loading = true;
    }

    unsetLoading() {
        this.loading = false;
    }
    unsetSearch() {
        this.search = []
    }

    reset() {
        this.word_family = {};
        this.family = [];
        this.searchResult = [];
    }

    resetStore() {
        this.store = {
            word:"",
            postion: "",
            pronounciation: "",
            arabic_pronounciation: "",
            sound: "",
            rythem: "",
            origin: "",
            bedeutungsgleich: "",
            translation: [],
            examples: [],
            synome: [],
            phonetik: [],
            hyphenation:[],
            etymologie: [],
            antonyme: [],
            dialects: [],
            phraseologismen: [],
            paromien: [],
            signifikante: [],
            derivate: [],
            grammatische:[],
            dialekte: []
        }
    
    }

}

decorate(WordStore, {
    words: observable,
    search: observable,
    loading: observable,
    word_family: observable,
    getWords: action,
    selectedWord: observable,
    setSlectedWord: action,
    duden: observable,
    setDuden: action,
    wiki: observable,
    setWiki: action,
    unsetSearch: action,
    google: observable,
    translateGoogle: action,
    arabdict: observable,
    getArabDict: action,
    showMore: action,
    getExamples: action,
    my_examples: observable,
    translateYandex: action,
    yandex: observable,
    settings: observable,
    fetchSettings: action,
    statistics: observable,
    getStatistics: action,
    store: observable,
    setStore: action,
    reset:action,
    saveFamily: action,
    definition: observable,
    getDefinition: action,
    getStored:action,
    stored: observable,
    toggleToast: observable,
    toastText: observable,
    toggleNote: action,
    addOwnMemberToFamily:action

  });

export default new WordStore();