import {
  GroupOutlined,
  InfoOutlined,
  LikeOutlined,
  TranslationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Skeleton,
  Statistic,
  Card,
  Tag,
  Button,
  Divider,
  Badge,
  PageHeader,
  List,
} from "antd";
import Meta from "antd/lib/card/Meta";
import React, { Component } from "react";
import Moment from "react-moment";
import { Tabs, Tab, Grid, StatsCard } from "tabler-react";
import api from "../utils/api";
import storage from "../utils/storage";
import moment from "moment";
import { io } from "socket.io-client";

export default class Admin extends Component {
  getAllStats = async () => {
    const { data } = await api.getAdminStats();
    const filter = this.groupItemBy(data.logs, "data.user");
    const logs_users = Object.keys(filter);
    this.setState({
      stats: data,
      loading: false,
      filter: data.logs,
      logs_users,
      selected: "All",
      selected_date: null,
      dates: [],
    });
    this.sortUsersLogs("All");
  };
  componentDidMount() {
    const user = storage.user();
    if (user?.payload?.is_admin) {
      this.socket = io({
        path: "/api/socket/",
        hostname: "http://app.gocognate.com",
      });
      this.getAllStats();
    } else {
      window.location.replace("/");
    }
  }
  state = {
    stats: {},
    loading: true,
    logs_users: [],
    selected: null,
    dates: [],
    filter: [],
    selected_date: null,
    kind: [],
    selected_kind: null,
    title: [],
    selected_title: null,
    avatar_colors: { D: "red", M: "blue", N: "pink", T: "green" },
    page: 1,
    pageSize: 10,
  };
  socket = null;

  sortUsersLogs = (username) => {
    const { logs } = this.state.stats;
    if (username === "All") {
      const dates = this.groupItemByDate(logs, `updated_at`);
      const kind = this.groupItemBy(logs, `kind`);
      const title = this.groupItemBy(logs, `title`);
      this.setState({
        filter: logs,
        selected: username,
        dates,
        kind,
        title,
        selected_date: null,
        selected_kind: null,
        selected_title: null,
      });
    } else {
      const grouped = this.groupItemBy(logs, "data.user");
      const dates = this.groupItemByDate(grouped[username], `updated_at`);
      const kind = this.groupItemBy(grouped[username], `kind`);
      const title = this.groupItemBy(grouped[username], `title`);
      this.setState({
        filter: grouped[username],
        selected: username,
        dates,
        kind,
        title,
      });
    }
  };

  groupItemByDate = (array, prop) => {
    return array.reduce((groups, item) => {
      const date = moment(item[prop]).fromNow();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(item);
      return groups;
    }, {});
  };

  groupItemBy = (array, property) => {
    var hash = {},
      props = property.split(".");
    for (var i = 0; i < array.length; i++) {
      var key = props.reduce(function (acc, prop) {
        return acc && acc[prop];
      }, array[i]);
      if (!hash[key]) hash[key] = [];
      hash[key].push(array[i]);
    }
    return hash;
  };

  render() {
    return (
      <div style={{ padding: 40 }}>
        <h1>Admin Page</h1>
        {this.state.loading ? (
          <Skeleton loading={this.state.loading} />
        ) : (
          <Tabs initialTab="Stats">
            <Tab title="Stats">
              <h1>Stats</h1>
              <Button
                onClick={async () => {
                  this.socket.emit("reset");
                }}
              >
                Reset Loading
              </Button>
              <Grid.Row>
                <Grid.Col>
                  <Statistic
                    title="English"
                    value={this.state.stats?.english?.finished}
                    suffix={this.state.stats?.english?.total}
                    prefix={<TranslationOutlined />}
                  />
                </Grid.Col>
                <Grid.Col>
                  <Statistic
                    title="Connect"
                    value={this.state.stats?.connect?.finished}
                    suffix={this.state.stats?.connect?.total}
                    prefix={<TranslationOutlined />}
                  />
                </Grid.Col>
                <Grid.Col>
                  <Statistic
                    title="German"
                    value={this.state.stats?.german?.finished}
                    suffix={this.state.stats?.german?.total}
                    prefix={<TranslationOutlined />}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                {Object.keys(this.state.stats?.logs_stats).map((log) => (
                  <Grid.Col>
                    <Statistic
                      style={{ marginTop: 30 }}
                      title={`User ${log} Added:`}
                      value={this.state.stats?.logs_stats[log].count}
                      suffix={
                        <div>
                          {Object.keys(
                            this.state.stats?.logs_stats[log].data
                          ).map((item) => (
                            <Tag
                              color="#55acee"
                              icon={
                                <span style={{ margin: 5 }}>
                                  {
                                    this.state.stats?.logs_stats[log].data[item]
                                      .length
                                  }
                                  in
                                </span>
                              }
                            >
                              {item}
                            </Tag>
                          ))}
                        </div>
                      }
                      prefix={<GroupOutlined />}
                    />
                  </Grid.Col>
                ))}
              </Grid.Row>
              {/* <Grid.Row>
                {Object.keys(this.state.stats?.logs_stats).map((log) => (
                  <Grid.Col>
                    <Statistic
                    style={{marginTop: 30}}
                      title={`User ${log} Added:`}
                      value={this.state.stats?.logs_stats[log].length}
                    //   suffix={this.state.stats?.german?.total}
                      prefix={<InfoOutlined />}
                    />
                  </Grid.Col>
                ))}
              </Grid.Row> */}
            </Tab>
            <Tab title="Users">
              <h1>Users</h1>
              <Grid.Row>
                {this.state.stats?.users.map((user) => (
                  <Grid.Col>
                    <Card
                      style={{ padding: 10 }}
                      actions={[user?.is_admin ? "Admin" : "User"]}
                    >
                      <Meta
                        avatar={<Avatar src={user.avatar} />}
                        title={user.username}
                        description={
                          user?.updated_at ? (
                            <Moment fromNow>{user?.updated_at}</Moment>
                          ) : (
                            "No Valid Date"
                          )
                        }
                      />
                    </Card>
                  </Grid.Col>
                ))}
              </Grid.Row>
            </Tab>
            <Tab title="Logs">
              <div style={{ padding: 20 }}>
                {/* Users */}
                <PageHeader
                  extra={
                    <Tag color="#87d068">
                      Results Found: {this.state.filter.length}
                    </Tag>
                  }
                  title="Filters"
                  tags={[]}
                >
                  <Tag
                    color={`${this.state.selected === "All" ? "#333" : ""}`}
                    onClick={() => this.sortUsersLogs("All")}
                  >
                    All
                  </Tag>
                  {this.state.logs_users.map((key) => (
                    <Tag
                      color={`${this.state.selected === key ? "#333" : ""}`}
                      onClick={() => this.sortUsersLogs(key)}
                    >
                      {key}
                    </Tag>
                  ))}
                  <Divider
                    type="vertical"
                    dashed
                    style={{ color: "red", background: "black" }}
                  />

                  {/* Dates */}
                  {Object.keys(this.state.dates).map((key) => (
                    <Tag
                      color={`${
                        this.state.selected_date === key ? "#555" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          filter: this.state.dates[key],
                          selected_date: key,
                        })
                      }
                    >
                      {key}
                    </Tag>
                  ))}
                  <Divider
                    type="vertical"
                    dashed
                    style={{ color: "red", background: "black" }}
                  />
                  {Object.keys(this.state.kind).map((key) => (
                    <Tag
                      color={`${
                        this.state.selected_kind === key ? "#2db7f5" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          filter: this.state.kind[key],
                          selected_kind: key,
                        })
                      }
                    >
                      {key}
                    </Tag>
                  ))}
                  <Divider
                    type="vertical"
                    dashed
                    style={{ color: "red", background: "black" }}
                  />
                  {Object.keys(this.state.title).map((key) => (
                    <Tag
                      color={`${
                        this.state.selected_title === key ? "#2cb7f5" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          filter: this.state.title[key],
                          selected_title: key,
                        })
                      }
                    >
                      {key}
                    </Tag>
                  ))}
                </PageHeader>
              </div>

              <List
                style={{ padding: 40 }}
                dataSource={this.state.filter}
                pagination={{
                  onChange: (page, pageSize) => {
                    this.setState({
                      page,
                      pageSize,
                    });
                  },
                  current: this.state.page,
                  pageSize: this.state.pageSize,
                }}
                renderItem={(log, index) => (
                  <List.Item
                    extra={[<Tag color="#2db7f5">{log.kind}</Tag>]}
                    actions={[<Tag color="red">{log.title}</Tag>]}
                  >
                    <List.Item.Meta
                      title={log?.data?.extra}
                      avatar={
                        <Avatar
                          style={{
                            backgroundColor:
                              this.state.avatar_colors[
                                log.data?.user.charAt(0).toUpperCase()
                              ],
                            verticalAlign: "middle",
                          }}
                        >
                          {log.data?.user.charAt(0).toUpperCase()}
                        </Avatar>
                      }
                      description={<Moment fromNow>{log?.created_at}</Moment>}
                    />
                  </List.Item>
                )}
              />
              {/* <Grid.Row cards deck>
                {this.state.filter?.map((log) => (
                  <Grid.Col md={4}>
                    <Card
                      type="inner"
                      extra={[<Tag color="#2db7f5">{log.kind}</Tag>]}
                      title={log.title}
                    >
                      <Meta
                        title={log?.data?.extra}
                        description={<Moment fromNow>{log?.created_at}</Moment>}
                      />
                      <Card style={{ width: 300, marginTop: 16 }}>
                        <Meta title={log.data?.user} />
                      </Card>

                    </Card>
                  </Grid.Col>
                ))}
              </Grid.Row> */}
            </Tab>
          </Tabs>
        )}
      </div>
    );
  }
}
