import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Loader } from "tabler-react";
import UserProfile from "./UserProfile";
import { Snackbar, Icon } from "@material-ui/core";
import { Button, Form, Dropdown, Nav } from "tabler-react";
import { Menu } from "antd";
import {
  ApiTwoTone,
  DatabaseTwoTone,
  FilterTwoTone,
  FundTwoTone,
  HomeTwoTone,
  ProfileTwoTone,
  SettingTwoTone,
} from "@ant-design/icons";

import auth from "../utils/storage";

class Navigation extends Component {
  state = {
    suggestions: [],
    searchTerm: [],
    currentPage: "home",
  };
  componentDidMount() {
    document
      .querySelector(".text-input")
      .addEventListener("keyup", this.onKeyUpSearch);
  }

  onKeyUpSearch = (e) => {
    const { pathname } = this.props.location;
    if (pathname.includes("outside")) {
      this.props.store.useSearch(e.target.value);
    } else if (pathname.includes("english")) {
      this.props.english.useSearch(e.target.value);
    }
  };
  searchItemSelected = async (e) => {
    const { pathname } = this.props.location;
    const term = this.state.searchTerm;
    if (pathname.includes("outside")) {
      const term = e.target.textContent;
      this.props.store.reset();
      this.props.store.setSlectedWord(term);
      this.props.store.getWordFamily(term);
      this.props.store.unsetSearch();

      if (this.props.history.location.pathname !== "/outside") {
        return this.props.history.push("/outside");
      }
    } else if (pathname.includes("english")) {
      this.props.english.unsetSearch();
      await this.props.english.getFamily(term);
    }
  };
  searchByEnteredWord = () => {
    const { pathname } = this.props.location;
    const term = this.state.searchTerm;
    if (pathname.includes("outside")) {
      this.props.store.reset();
      this.props.store.setSlectedWord(term);
      this.props.store.getWordFamily(term);
      this.props.store.unsetSearch();
    }
    if (pathname.includes("english")) {
      this.props.english.reset();
      this.props.english.setSlectedWord(term);
      this.props.english.getWordFamily(term);
      this.props.english.unsetSearch();
    }
  };

  toggleMenuSelection = (id) => {
    this.setState({
      currentPage: id,
    });
  };

  render() {
    const user = auth.user();

    return (
      <React.Fragment>
        <Menu
        theme="dark"
          mode="horizontal"
          style={{
            position: "sticky",
            top:0,
            width: "100%",
            zIndex: 1000,
          }}
          selectedKeys={[this.state.currentPage]}
        >
          <Menu.Item
            key={"home"}
            onClick={() => this.toggleMenuSelection("home")}
            icon={<HomeTwoTone />}
          >
            <Link className="link" to="/">
              Home
            </Link>
          </Menu.Item>

          <Menu.SubMenu title="Filters" icon={<FilterTwoTone />}>
            <Menu.Item
              key={"german_filters"}
              onClick={(e) => this.toggleMenuSelection("german_filters")}
            >
              <Link className="link" to="/outside">
                German Filter
              </Link>
            </Menu.Item>
            <Menu.Item
              key={"english_filters"}
              onClick={(e) => this.toggleMenuSelection("english_filters")}
            >
              <Link className="link" to="/english">
                English Filter
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu title="Families" icon={<DatabaseTwoTone />}>
            <Menu.Item
              key={"german_families"}
              onClick={(e) => this.toggleMenuSelection("german_families")}
            >
              <Link className="link" to="/families">
                German Families
              </Link>
            </Menu.Item>
            <Menu.Item
              key={"english_families"}
              onClick={(e) => this.toggleMenuSelection("english_families")}
            >
              <Link className="link" to="/families/english">
                English Families
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu title="Connnect" icon={<ApiTwoTone />}>
            <Menu.Item key={"connect"}>
              <Link
                onClick={(e) => this.toggleMenuSelection("connect")}
                className="link"
                to="/families/connect"
              >
                German English
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu title={user.payload.username} icon={<ProfileTwoTone />}>
            <Menu.Item key={"settings"} icon={<SettingTwoTone />}>
              <Link
                onClick={(e) => this.toggleMenuSelection("settings")}
                className="link"
                to="/settings"
              >
                Settings
              </Link>
            </Menu.Item>
            <Menu.Item key={"profile"} icon={<ProfileTwoTone />}>
              <Link
                onClick={(e) => this.toggleMenuSelection("profile")}
                className="link"
                to="/profile"
              >
                Profile
              </Link>
            </Menu.Item>
            {user.payload.is_admin ? (
              <Menu.Item icon={<FundTwoTone />} key={"admin"}>
                <Link
                  onClick={(e) => this.toggleMenuSelection("admin")}
                  className="link"
                  to="/admin"
                >
                  Admin
                </Link>
              </Menu.Item>
            ) : null}
            <Menu.Item key={"logout"}>
              <Link
                onClick={(e) => auth.logout()}
                className="link"
                to="/prfile"
              >
                Logout
              </Link>
            </Menu.Item>
           
          </Menu.SubMenu>
        </Menu>

        <ul className="navigation" style={{ display: "none" }}>
          <li>
            <UserProfile />
          </li>
          <li className="nav-item">
            <Link className="link" to="/">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link className="link" to="/outside">
              Filter
            </Link>
          </li>
          <li className="nav-item">
            <Link className="link" to="/settings">
              Settings
            </Link>
          </li>
          <li className="nav-item">
            <Link className="link" to="/english">
              English
            </Link>
          </li>
          <li className="nav-item">
            <Link className="link" to="/families">
              German Families
            </Link>
          </li>
          <li className="nav-item">
            <Link className="link" to="/families/english">
              English Families
            </Link>
          </li>
          <li className="nav-item">
            <Link className="link" to="/families/connect">
              Connnect DE-EN
            </Link>
          </li>

          <li
            style={{
              display: this.props.location.pathname !== "/" ? "" : "none",
            }}
            className="nav-search-bar nav-item"
          >
            <div>
              <div>
                <Form.InputGroup
                  className="text-input-content"
                  append={
                    <Button
                      RootComponent="a"
                      color="primary"
                      onClick={this.searchByEnteredWord}
                    >
                      Go!
                    </Button>
                  }
                >
                  <Form.Input
                    onChange={(e) =>
                      this.setState({ searchTerm: e.target.value })
                    }
                    placeholder="search..."
                    value={this.state.searchTerm}
                    onClick={this.searchByEnteredWord}
                    list="searchList"
                    className="text-input"
                  />
                </Form.InputGroup>
                <div className="search-items">
                  {this.props.store.search &&
                    this.props.store.search.map((word) => (
                      <div
                        key={word.id}
                        onClick={this.searchItemSelected}
                        className="search-item"
                      >
                        {word.word}
                      </div>
                    ))}
                  {this.props.english.search &&
                    this.props.english.search.map((word) => (
                      <div
                        key={word.id}
                        onClick={this.searchItemSelected}
                        className="search-item"
                      >
                        {word.word}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </li>
          <li>
            {this.props.store.loading || this.props.english.loading ? (
              <Loader />
            ) : null}
          </li>
          <li className="nav-item details-in-nav ">
            {this.props.store.word_family.detail ? (
              <div style={{ marginLeft: 20 }}>
                <Dropdown
                  type="button"
                  color="primary"
                  icon="globe"
                  triggerContent="Word Details"
                  itemsObject={[
                    { value: `Stripped : ${this.props.store.family.striped}` },
                    { isDivider: true },
                    { value: `Origin : ${this.props.store.family.origin}` },
                    { isDivider: true },
                    {
                      value: `Count : ${this.props.store.word_family.detail.length}`,
                    },
                    { isDivider: true },
                    { value: `ex-root : ${this.props.store.store.text}` },
                  ]}
                />
              </div>
            ) : null}
          </li>
        </ul>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.props.store.toggleToast}
          autoHideDuration={5000}
          message={<span id="message-id">{this.props.store.toastText}</span>}
        />
      </React.Fragment>
    );
  }
}
export default inject("store", "english")(observer(Navigation));
