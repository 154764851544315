import React, { Component } from 'react'
import TabsItem from './TabsItem'
import WordCheckbox from './WordCheckbox';


export default class Duden extends Component {
    state = {
        sound: '',
        examples: [],
        type: '',
        pronunciation:''
    }

    handleExampleChange = (value, status) => {
        const {examples} = this.state;
        if (status) {
            examples.push(value);
            this.setState({examples})
            this.props.store.setStore('examples', examples);
        }else {
            const data = examples.filter(item => item !== value);
            this.setState({
                examples: data
            })
            this.props.store.setStore('examples', data);
        }
    }

    handleType = (type, status) => {
        if(status) {
            this.props.store.setStore('postion', type);
        }else {
            this.props.store.setStore('postion', '');
        }
    }

    handlePron = (pronounciation, status) => {
        if(status) {
            this.props.store.setStore('pronounciation', pronounciation);
        }else {
            this.props.store.setStore('pronounciation', '');            
        }
    }

    handleSound = (value, status) => {
        if(status) {
            this.props.store.setStore('sound', value );
        }else {
            this.props.store.setStore('sound', '');
        }
    }
    render() {
        const {duden} = this.props.store

        return (
            <div>
                <h2>Duden Results</h2>
                {Object.keys(duden).length > 0  ? 
                    <div style={{padding: '10px'}}>
                        {duden.sound 
                            ? <WordCheckbox value={duden.sound} handleChange={this.handleSound} label="" ><audio controls src={duden.sound} /></WordCheckbox>
                            : <p>no audio found...</p>}
                        <WordCheckbox handleChange={this.handleType} value={duden.type} label={duden.type} />
                        <WordCheckbox handleChange={this.handlePron} value={duden.pronunciation} label={duden.pronunciation} />
                        {/* <TabsItem handleChange={this.handleExampleChange} title="Examples" examples={duden.examples} /> */}
                        {duden.examples && duden.examples.map((example, index) => (
                            <WordCheckbox value={example} label={example} handleChange={this.handleExampleChange} key={index} />
                        ))}
                    </div>
                : <p>No Duden results...</p>}
                
                </div>
        )
    }
}
