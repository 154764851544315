import React, { Component } from 'react'
import { observer, inject } from 'mobx-react';
import { Icon } from 'tabler-react';
import { Button as IconButton } from 'tabler-react'
import api from '../utils/api';
import { Collapse, Empty, Skeleton, Badge, List, message, Popconfirm, Tooltip, Card, Statistic, Button } from 'antd';
import InfinteVerbsSCroll from '../components/InfinteVerbsSCroll'


const { Panel } = Collapse;

class Home extends Component {
    state = {
        verbs: [],
        count: 0,
        limit: 25,
        next: 0,
        loading: false,
        mode: "Compact"
    }



    componentDidMount() {
        this.loadData()
    }


    loadData = async () => {
        const { limit, next } = this.state

        await this.props.store.getStatistics();
        const results = await api.getVerbsAdded(limit, next)
        this.setState({
            verbs: results.data['results'],
            count: results.data['count'],
            loading: false
        })
    }

    deleteMember = async (verb, member) => {
        const { _id } = verb
        const { word } = member
        try {
            this.setState({ loading: true })
            const result = await api.deleteMemberInVerbs(_id, word)
            message.success(`${word} Deleted Successfully`, 3, async () => {
                await this.loadData()
            })
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false
            })
            message.error("Something went wrong", 3)

        }

    }
    deleteVerb = async (verb) => {
        try {
            const { _id } = verb
            this.setState({ loading: true })
            const res = await api.deleteVerb(_id)
            message.success(`${verb.verb} Deleted Successfully`, 3, async () => {
                await this.loadData()
            })
        } catch (error) {
            this.setState({
                loading: false
            })
            message.error("Something went wrong", 3)
            console.log(error);
        }
    }

    handleInfiniteOnLoad = async () => {
        let { limit, next } = this.state
        next += parseInt(limit)
        const results = await api.getVerbsAdded(limit, next)
        this.setState({
            verbs: [...this.state.verbs, ...results.data['results']],
            count: results.data['count'],
            loading: false,
            next
        })
    }

    render() {
        const { statistics, loading } = this.props.store
        return (
            <div style={{ padding: "20px", }}>
                <h1>Statistics </h1>
                <div>
                    <IconButton onClick={() => this.setState({ mode: this.state.mode === "Compact" ? "Loose" : "Compact" })} icon={this.state.mode === "Compact" ? "layout" : "layers"} color="warning">
                        Change Mode ({this.state.mode})
                    </IconButton>
                </div>
                {/* <h4>Families Added {this.state.count}</h4> */}
                <Card style={{ marginTop: 40, marginBottom: 20 }} loading={this.state.loading} >
                    <Statistic
                        title="Verbs Added"
                        value={this.state.count}
                        precision={0}
                        valueStyle={{ color: '#3f8600' }}
                        suffix="Verb"
                    />
                </Card>
                <div style={{ padding: 20 }}>
                    {
                        !this.state.loading && this.state.verbs.length > 0 ? (
                            this.state.mode === "Compact" ? (
                                <>
                                    <InfinteVerbsSCroll handleInfiniteOnLoad={this.handleInfiniteOnLoad} verbs={this.state.verbs} />
                                </>

                            ) : (

                                    <Collapse bordered defaultActiveKey={['1']}>

                                        {this.state.verbs.map((verb, index) => (
                                            <Panel showArrow header={`${index + 1} - Verb: ${verb.verb}  - Striped: ${verb.striped} - Origin: ${verb.origin} `}
                                                extra={[<Badge showZero count={verb.members.length} ></Badge>]} key={verb._id}>
                                                <Popconfirm
                                                    title="Are you sure to delete this Verb With its Members ?"
                                                    onConfirm={() => this.deleteVerb(verb)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Tooltip title="Delete The Verb ?">
                                                        <Icon name="trash-2" />
                                                    </Tooltip>

                                                </Popconfirm>
                                                {
                                                    this.state.loading ? <Skeleton /> :
                                                        verb.members.map(member => (
                                                            <List.Item extra={[
                                                                <Popconfirm
                                                                    title="Are you sure to delete this Member?"
                                                                    onConfirm={() => this.deleteMember(verb, member)}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <Icon name="trash" />

                                                                </Popconfirm>


                                                            ]} draggable  >{member.word}</List.Item>
                                                        ))
                                                }
                                            </Panel>
                                        ))}

                                    </Collapse>
                                )

                        ) : <Empty />

                    }
                    {/* Loadmore */}
                    {
                        this.state.verbs.length > 0 && (
                            <div style={{ marginTop: 20 }}>
                                <Button style={{ height: 40 }} block type="dashed" title="Load More" onClick={this.handleInfiniteOnLoad}>Load More</Button>
                            </div>
                        )
                    }
                    {/* <MyCard title="Families and Members" css="home_stat" active={loading}>
                        {statistics.hasOwnProperty('families') ? (
                            <div>
                                <StatsCard layout={4} movement={`Members : ${statistics.members.count}`} total={statistics.families.count} label="Families Added" />
                                <Grid.Row>
                                    {
                                        statistics.families.results !== null 
                                        ? statistics.families.results.map(item => (
                                            <Grid.Col>
                                                <MyCard closed={true} title={`${item.word} - ${item.members.length}`}>{item.members.map(member => (
                                                    <p>{member.word}</p>
                                                ))}</MyCard>
                                            </Grid.Col>
                                        )) : null
                                    }
                                </Grid.Row>
                            </div>
                        ) : <h4>No results ...</h4>}
                    </MyCard> */}

                </div>
            </div>
        )
    }
}

export default inject('store')(observer(Home));