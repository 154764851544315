import React, { Component } from 'react'

export default class Arabdict extends Component {
    render() {
        const {arabdict} = this.props.store
        return (
            <div>
                 {
                    arabdict.length > 0
                    ? arabdict.map(item => (
                        <div key={Math.random()} style={{backgroundColor:"#333", color:"#fff"}}>
                        <p style={{padding: '5px'}}>{item.latin.term} - <span>{item.latin.pos}</span> - <span>{item.latin.artikel}</span></p>
                        <p style={{padding: '10px'}}>{item.arabic.term} - <span>{item.arabic.pos}</span></p>
                        </div>

                    ))
                    : <p>No results found...</p>
                }
            </div>
        )
    }
}
