class Storage {
    constructor(storage) {
        this.storage = storage;
        this.init()
    }

    init() {
        if(this.storage.getItem('words_count') === null) {
            this.storage.setItem('words_count', 0)
        }
    }

    value() {
        return this.storage.getItem('words_count');
    }

    update() {
        this.storage.setItem('words_count', parseInt(this.value()) + 25);
    }

    login(data) {
        data.isAuth = true;
        this.storage.setItem('auth', JSON.stringify(data))
    }

    logout() {
        this.storage.removeItem('auth');
    }

    user() {
        return JSON.parse(this.storage.getItem('auth'));
    }

    token() {
        if (this.storage.getItem('auth')) {
            return JSON.parse(this.storage.getItem('auth')).token;
        }else {
            return null;
        }
    }
    get isLoged() {
        const results = this.storage.getItem('auth') !== null ? JSON.parse(this.storage.getItem('auth')) : null
        if(results !== null) {
            return results.isAuth;
        }else {
            return false;
        }
    }
}




export default new Storage(window.localStorage);