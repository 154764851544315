import React, { Component } from 'react'
import { Badge, List } from 'antd';

export default class InfinteVerbsSCroll extends Component {
    render() {
        return (
                <List
                    loading={this.props.loading}
                    loadMore={this.props.handleInfiniteOnLoad}
                    dataSource={this.props.verbs}
                    renderItem={(item, index) => (
                        <List.Item key={index}>
                            <List.Item.Meta
                                title={<strong>{item.verb}</strong>}
                                description={<p>Striped: {item.striped} - Origin: {item.origin} </p>}
                            />
                            <Badge count={item.members.length} showZero style={{backgroundColor: "#87d068"}} />
                        </List.Item>
                    )}

                />

        )
    }
}
