import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'
import '../styles/filter.css';
import SidebarWord from '../components/SidebarWord';
import ScrollDialog from '../components/MyDialog';
import EnglishItem from '../components/EnglishItem';
import { Dialog } from '@material-ui/core';
import EnglishDetails from '../components/EnglishDetails';



class EnglishFilter extends Component {
  componentDidMount() {
    this.getIntialData();
  }

  getIntialData =  async () => {
    await this.props.english.getWords();
  }

  getWordFamily = async (word) => {
    await this.props.english.getFamily(word);
  }

  showFamilyDialog = async (word) => {
    await this.props.english.getWordDetails(word);
    this.setState({
      show_family: true
    })
  }

  state = {
    data: [],
    show_family: false
  }
  render() {
    const {words, family} = this.props.english;
    return (
      <React.Fragment>
        <div className="grid-container">
          <div className="main" style={{height: "85%", overflowY: "scroll" }}>
            {
              family && family.map(item => (
                  <EnglishItem word={item.word} handleClickOpen={() => this.showFamilyDialog(item.word)} ratio={item.ratio} />
                )
              )}
          </div>
          <div className="sidebar">
            <div id="top"></div>
            {words.length > 0 ? (
              words.map(item => (
                <SidebarWord link={'english'} word={item.word} wordClicked={()=> this.props.english.getFamily(item.word)} />
              ))
            ): null}
          </div> 
            <ScrollDialog open={this.state.show_family} handleClose={()=> this.setState({show_family: false})}>
              <EnglishDetails store={this.props.english} />
            </ScrollDialog>
        </div>
      </React.Fragment>
    )
  }
}

export default inject('english')(observer(EnglishFilter));
