import React, { Component } from 'react'
import WordCheckbox from './WordCheckbox';

export default class Synome extends Component {
    state = {
        synonyme: []
    }
    addSynome = (value, status) => {
        const {synonyme} = this.state;
        if(status) {
            synonyme.push(value);
            this.setState({synonyme})
            this.props.store.setStore('synome', synonyme);
        }else {
            const data = synonyme.filter(item => item !== value);
            this.setState({synonyme: data});
            this.props.store.setStore('synome', data);
        }
    }
    render() {
        const {synonyme} = this.props.store.duden;
        return (
            <div>
                {synonyme && synonyme.map(item => (
                    <WordCheckbox value={item} label={item} handleChange={this.addSynome} />
                ))}
            </div>
        )
    }
}
