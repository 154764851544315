import React, { Component } from 'react'
import {Form, Button} from 'tabler-react'
import { observer, inject } from 'mobx-react';

class Notes extends Component {
    state = {
        word:"",
        postion: "",
        rythem: "",
        sound: "",
        origin: "",
        translation: [],
        examples: [],
        bedeutungsgleich: "",
        pronounciation: "",
        arabic_pronounciation: "",
        synome: [],
        phonetik: [],
        hyphenation:[],
        etymologie: [],
        antonyme: [],
        dialects: [],
        phraseologismen: [],
        paromien: [],
        signifikante: [],
        derivate: [],
        grammatische:[],
        dialekte: [],
        wc: 0,
        item: ""
    }

    handleChange = (title, value) => {
        this.props.store.setStore(title, value);
    }

    addExample = () => {
        const examples = [...this.state.examples, this.state.item];
        this.setState({
            examples,
            item: ""
        })

        this.props.store.setStore('examples', [this.state.item, ...this.props.store.store.examples]);
    }

    removeExample =  (e) => {
        const examples = [...this.props.store.store.examples.filter(item => item !== e.target.value)];
        this.setState({
            examples
        })

        this.props.store.setStore('examples', [...examples])
    }

    addMeaning = (e) => {
        if(this.state.bedeutungsgleich.length > 0){
            this.props.store.setStore('bedeutungsgleich',this.state.bedeutungsgleich);
        }else {
            this.props.store.setStore('bedeutungsgleich',this.props.store.definition.description);            
        }
    }
    render() {
        return (
            <div>
                <Form.Group label={<Form.Label aside={`${this.state.wc}/1000`}>Word Meaning</Form.Label>}>
                    <Form.Textarea
                        onChange={(e) => this.setState({bedeutungsgleich: e.target.value})}
                        placeholder="Word Meaning.."
                        value={this.state.bedeutungsgleich}
                        rows={6}>
                            {this.props.store.definition.description}
                    </Form.Textarea>
                        <Button color="primary" onClick={this.addMeaning}>Add Meaning</Button>
                    </Form.Group>
                <Form.Label>Examples</Form.Label>
                {
                    this.state.examples && this.state.examples.map(item => (
                        <p>
                            <Button key={Math.random()} value={item} onClick={this.removeExample}>{item}</Button>
                        </p>
                    ))
                }

                <Form.InputGroup>
                    <Form.Input value={this.state.item} onChange={(e) => this.setState({item: e.target.value})} placeholder="Example..." />
                    <Form.InputGroupAppend>
                    <Button
                        onClick={this.addExample}
                        RootComponent="a"
                        color="primary"
                    >
                        Add
                    </Button>
                    </Form.InputGroupAppend>
                </Form.InputGroup>
            </div>
        )
    }
}


export default inject('store')(observer(Notes));