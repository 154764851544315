import { Button } from "antd";
import React, { Component } from "react";
import { Profile } from "tabler-react";
import storage from "../utils/storage";

export default class ProfilePage extends Component {
  componentDidMount() {
    this.setState({
      user: storage.user(),
    });
  }
  state = {
    user: {},
  };
  render() {
    return (
      <>
        <Profile
          name={String(this.state.user?.payload?.username).toUpperCase()}
          avatarURL={this.state.user?.payload?.avatar}
          backgroundURL={
            "https://images.theconversation.com/files/161033/original/image-20170315-20495-1iam3aw.jpg"
          }
          bio="Gocognater"
        >
        </Profile>
      </>
    );
  }
}
