import React, {Component} from 'react'
import {StatsCard, TabbedCard, Tab, Grid, Card} from 'tabler-react'
export default class EnglishDetails extends Component {

    createMarkup(data="<p>None</p>") {
        return {__html: data};
      }

  render() {
    return (
      <div style={{width:"500px"}}>
        <StatsCard
          layout={3}
          movement={this.props.store.word_details.data.confidence}
          total={parseInt(this.props.store.word_details.data.confidence * 100)}
          label="Confidence"/>
        <TabbedCard initialTab="Overview">
        <Tab title="Overview">
            <p>possible mistakes:</p>
              {
                  this.props.store.word_details.data['possible-mistakes'] !== null ?
                  this.props.store.word_details.data['possible-mistakes'].flat(2).filter(item => item !== null && item.length > 2).map(item => (
                      <div>
                          <p dangerouslySetInnerHTML={this.createMarkup(item)}></p>
                      </div>
                  )) : "Not Found!"
              }
              <p>
                    pronunciation: 
                    
              </p>
              <p>
                    {this.props.store.word_details.data.pronunciation ? this.props.store.word_details.data.pronunciation : "Not Found!"}
              </p>

          </Tab>
          <Tab title="Examples">
          {
                      this.props.store.word_details.data.examples ?
                      this.props.store.word_details.data.examples[0].filter(item => item[0].search('m_en_us') < 0 && item[0].length > 0 ).map(item => (
                          <Card body={ item[0]}/>
                      )): <p>None</p>
                  }

          </Tab>
          <Tab title="Definition">
          {
                      this.props.store.word_details.data.definitions ?
                      this.props.store.word_details.data.definitions[0][1].flat(1).filter(item => item.search('m_en_us') < 0 && item.length > 0 ).map(item => (
                          <Card body={ item}/>
                      )): <p>None</p>
                  }
          </Tab>
          <Tab title="Synonyms">
            <Grid.Row cards >
              <Grid.Col md={12}>
                  {
                      this.props.store.word_details.data.synonyms !== null ?
                      this.props.store.word_details.data.synonyms[0][1].flat(2).filter(item => item.search('m_en_us') < 0 && item.length > 0 ).map(item => (
                          <Card body={ item}/>
                      )): <p>None</p>
                  }
              </Grid.Col>
            </Grid.Row>
          </Tab>
        </TabbedCard>
      </div>
    )
  }
}
