import React, { Component } from 'react'
import { Button } from '@material-ui/core';
import WordCheckbox from './WordCheckbox';

export default class Examples extends Component {

    handleChange = (value, status, title) => {
        const data = this.props.store.store[title] || [];
        if(status) {
            this.props.store.setStore(title, [...data, value]);
        }else {
            this.props.store.setStore(title,  data.filter(item => item !== value));
        }
    }

    render() {
        return (
            <div>
                <h4>Examples: <span>Number of examples Found: {this.props.store.my_examples.examples ? this.props.store.my_examples.examples.length : 0 }</span></h4>
                    {/* <Button variant="contained" color="primary" onClick={() => this.props.store.getExamples(this.props.store.selectedWord)}>Get Examples</Button> */}
                    <div style={{padding:"20px"}}>
                        {this.props.store.my_examples.examples ? this.props.store.my_examples.examples.map(item => (
                        <WordCheckbox title="examples" handleChange={this.handleChange} value={item} label={item} />
                        )): <p>No Examples found :( </p>}
                    </div>
            </div>
        )
    }
}
