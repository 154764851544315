import {
  CheckOutlined,
  DeleteFilled,
  InfoCircleFilled,
  MinusOutlined,
  PlusOutlined,
  PlusSquareFilled,
  StarFilled,
  VerticalAlignMiddleOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Carousel,
  Col,
  Input,
  List,
  Modal,
  notification,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Skeleton,
  Space,
  Spin,
  Statistic,
} from "antd";
import React, { Component } from "react";
import api from "../utils/api";

import { debounce } from "lodash";

export default class Combine extends Component {
  state = {
    data: [],
    pagination: 10,
    pageNumber: 1,
    lastAdded: "",
    selected: {},
    moveValue: null,
    modelLoading: false,
    saving: false,
    details: {},
    toReview: [],
  };

  componentDidMount() {
    this.getInitalData();
  }

  getInitalData = async () => {
    this.setState({ loading: true });
    const pagination = localStorage.getItem("pagination") || 10;
    const pageNumber = localStorage.getItem("pageNumber") || 1;
    const toReview = JSON.parse(localStorage.getItem("toReview")) || [];
    const { data } = await api.getConnectionData();
    const lastAdded = data.data.filter((item) => item.saved.length > 0).pop();
    console.log(lastAdded);
    this.setState({
      data: data.data,
      loading: false,
      pagination,
      pageNumber,
      lastAdded: lastAdded?.verb,
      showDetails: false,
      toReview,
    });
  };

  checkIfadded = (target) => {
    const { selected } = this.state;
    if (selected.saved.find((item) => item.verb === target.verb)) {
      return true;
    }
    return false;
  };

  showTranslation = async (word) => {
    const { data } = await api.getGoogle(word, "en");
    const def = await api.getDefinition(word);
    notification.open({
      message: (
        <p style={{ backgroundColor: "#333", color: "#fff" }}>
          Translationg: {<strong>{word}</strong>}
          <p>
            Google Translation : <strong>{data.text}</strong>
          </p>
        </p>
      ),
      description: (
        <div>
          {`${def.data?.data?.definitions?.pons}`.length > 500 ? (
            <details>
              <summary>Definitions</summary>
              <p>{def.data?.data?.definitions?.pons}</p>
            </details>
          ) : (
            <p>{def.data?.data?.definitions?.pons}</p>
          )}
          <table>
            <tbody>
              <tr>
                <td>Language</td>
                <td>Text</td>
              </tr>
              {def.data?.data?.translations.map((item) => (
                <tr>
                  <>
                    <td>[{item.lang}]</td>
                    <td>
                      <strong>{item.value}</strong>
                    </td>
                  </>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ),
      style: { backgroundColor: "#333", color: "#fff" },
      placement: "topRight",
    });
  };

  getDetails = async (target) => {
    const { value } = target;
    const { data } = await api.getConnectionAddDetails(value, "en");

    this.setState({ details: data });
  };

  toggleFavorites = async (item) => {
    if (localStorage.getItem("toReview") === null) {
      localStorage.setItem("toReview", JSON.stringify([]));
    }

    const favorites = JSON.parse(localStorage.getItem("toReview"));
    if (favorites.find((e) => item._id === e._id)) {
      const rest = favorites.filter((e) => item._id !== e._id);
      localStorage.setItem("toReview", JSON.stringify(rest));
    } else {
      localStorage.setItem("toReview", JSON.stringify([item, ...favorites]));
    }
    this.setState({
      toReview: JSON.parse(localStorage.getItem("toReview")),
    });
  };

  isFavorite = (id) => {
    if (this.state.toReview.find((item) => item._id === id)) return true;
    return false;
  };

  render() {
    return this.state.loading ? (
      <Skeleton />
    ) : (
      <div style={{ padding: 20, backgroundColor: "#999", color: "#fff" }}>
        <h1>Connect Families</h1>
        <Row gutter={12} style={{ padding: 20 }}>
          <Col span={4}>
            <Statistic title="verbs count" value={this.state.data.length} />
          </Col>
          <Col span={4}>
            <Statistic title="Current Page" value={this.state.pageNumber} />
          </Col>
          <Col span={4}>
            <Statistic
              title="Saved Data"
              value={
                this.state.data.filter((item) => item.saved.length > 0).length
              }
              loading
            />
          </Col>
          <Col span={4}>
            <Statistic
              title="Last Saved"
              value={this.state.lastAdded}
              loading
            />
          </Col>
        </Row>
        <List
          itemLayout="vertical"
          size="large"
          pagination={{
            defaultCurrent: this.state.pageNumber,
            onShowSizeChange: (current) => {},
            onChange: (page, pageSize) => {
              this.setState({ pagination: pageSize, pageNumber: page });
              localStorage.setItem("pageNumber", page);
              localStorage.setItem("pagination", pageSize);
            },
            pageSize: this.state.pagination,
          }}
          dataSource={this.state.data}
          footer={
            <div>
              <b>ant design</b> footer part
            </div>
          }
          renderItem={(item) => (
            <List.Item
              extra={[
                <Button
                  onClick={() =>
                    this.setState({
                      showDetails: !this.state.showDetails,
                      selected: item,
                    })
                  }
                >
                  Details
                </Button>,

                this.isFavorite(item._id) ? (
                  <Button
                    style={{ backgroundColor: "red", borderColor: "red", color: "white" }}
                    onClick={() => {
                      this.toggleFavorites(item);
                    }}
                  >
                    <StarFilled style={{ color: "white" }} />
                  </Button>
                ) : (
                  <Button
                    style={{ backgroundColor: "white", borderColor: "red" }}
                    onClick={() => {
                      this.toggleFavorites(item);
                    }}
                  >
                    <StarFilled style={{ color: "" }} />
                  </Button>
                ),
              ]}
              key={item.verb}
              actions={[]}
            >
              <List.Item.Meta
                avatar={<Avatar style={{ backgroundColor: "#333" }}>
                  {
                    item.saved.length > 0 ? <CheckOutlined style={{color: "yellow"}} /> : "DE"
                  }
                </Avatar>}
                title={<a href={item.href}>{item.verb}</a>}
                description={item.striped}
              />
              {item.content}
            </List.Item>
          )}
        />

        <Modal
          confirmLoading={this.state.saving}
          width={"90%"}
          title={
            <div>
              <Row gutter={16}>
                <Col span={8}>
                  <Card
                    loading={this.state.modelLoading}
                    hoverable
                    onClick={async () => {
                      this.setState({ modelLoading: true });
                      await this.showTranslation(this.state.selected.verb);
                      this.setState({ modelLoading: false });
                    }}
                  >
                    <Statistic
                      title="Verb"
                      value={this.state.selected.verb}
                      valueStyle={{ color: "#3f8600" }}
                    />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card hoverable>
                    <Statistic
                      title="No Prefix"
                      value={this.state.selected.stripped}
                      valueStyle={{ color: "#cf1322" }}
                    />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card hoverable bordered>
                    <Statistic
                      title="No Vowels"
                      value={this.state.selected.voweled}
                      valueStyle={{ color: "#ef1322" }}
                    />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card hoverable bordered>
                    <Statistic
                      title="Saved"
                      value={this.state.selected.saved?.length}
                      valueStyle={{ color: "#ef1322" }}
                    />
                  </Card>
                </Col>
              </Row>
            </div>
          }
          onCancel={() =>
            this.setState({ showDetails: false, modelLoading: false })
          }
          onOk={async () => {
            this.setState({
              saving: true,
            });
            await api.saveConnectionData(this.state.selected);
            this.setState({
              saving: false,
              showDetails: false,
            });
          }}
          visible={this.state.showDetails}
        >
          {this.state.selected ? (
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              {this.state.selected.results?.map((section, sectionIndex) => (
                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    padding: 20,
                    border: ".5px black solid",
                    margin: 10,
                  }}
                >
                  <Statistic
                    title={section.title}
                    value={section.results.length}
                    valueStyle={{ color: "#ef1322" }}
                    suffix={
                      <Popover
                        onVisibleChange={(vis) => {
                          if (!vis) {
                            this.setState({ details: {} });
                          }
                        }}
                        trigger={"click"}
                        content={
                          <div>
                            <h4>Add New</h4>
                            <Input
                              onChange={async ({ target }) => {
                                await this.getDetails(target);
                              }}
                              style={{ margin: 5, width: 200 }}
                              placeholder="Verb"
                            />
                            <br />
                            <Input
                              style={{ margin: 5, width: 200 }}
                              placeholder="Stripped Verb"
                              value={this.state.details?.stripped}
                            />
                            <br />
                            <Input
                              style={{ margin: 5, width: 200 }}
                              placeholder="Vowels Removed"
                              value={this.state.details?.voweleRemoved}
                            />
                            <Button
                              block
                              type="primary"
                              onClick={() => {
                                const { selected } = this.state;
                                const { word, stripped, voweleRemoved } =
                                  this.state.details;
                                section.results.push({
                                  verb: word,
                                  voweled: voweleRemoved,
                                  stripped,
                                });
                                this.setState({ selected });
                              }}
                            >
                              Add Word
                            </Button>
                          </div>
                        }
                      >
                        <Button
                          style={{ marginLeft: 10 }}
                          icon={<PlusOutlined />}
                        >
                          Add
                        </Button>
                      </Popover>
                    }
                  />
                  <List
                    dataSource={section.results}
                    renderItem={(item, index) => (
                      <List.Item
                        extra={[
                          <Button
                            onClick={() => {
                              const { selected } = this.state;
                              if (
                                selected.saved.find(
                                  (it) => it.verb === item.verb
                                )
                              ) {
                                const moved = selected.saved.filter(
                                  (it) => it.verb !== item.verb
                                );
                                selected.saved = moved;
                                this.setState({
                                  selected,
                                });
                              } else {
                                selected.saved.push(item);
                                this.setState({
                                  selected,
                                });
                              }
                            }}
                            icon={
                              this.checkIfadded(item) ? (
                                <DeleteFilled style={{ color: "red" }} />
                              ) : (
                                <PlusOutlined style={{ color: "blue" }} />
                              )
                            }
                          />,
                          <Button
                            href={`https://www.google.com/search?q=${item.verb}`}
                            target="_blank"
                            icon={<InfoCircleFilled />}
                            style={{ color: "grey" }}
                          />,
                          <Popover
                            onVisibleChange={(visible) => {
                              if (!visible) {
                                this.setState({
                                  moveValue: null,
                                });
                              }
                            }}
                            content={
                              <Card
                                actions={[
                                  <Button
                                    type="primary"
                                    disabled={this.state.moveValue === null}
                                    onClick={() => {
                                      const { moveValue, selected } =
                                        this.state;
                                      const to = selected.results[moveValue];
                                      // let from = selected.results.find(s => s.type === section.type)
                                      section.results = section.results.filter(
                                        (i) => i.verb !== item.verb
                                      );
                                      to.results.push(item);
                                      this.setState({
                                        selected,
                                      });
                                      console.log(selected);
                                    }}
                                  >
                                    Move
                                  </Button>,
                                ]}
                              >
                                <Radio.Group
                                  onChange={(val) => {
                                    this.setState({
                                      moveValue: val.target.value,
                                    });
                                  }}
                                  value={this.state.moveValue}
                                >
                                  <Space direction="vertical">
                                    <Radio
                                      disabled={sectionIndex === 0}
                                      value={0}
                                    >
                                      Exact
                                    </Radio>
                                    <Radio
                                      disabled={sectionIndex === 1}
                                      value={1}
                                    >
                                      Alternative
                                    </Radio>
                                    <Radio
                                      disabled={sectionIndex === 2}
                                      value={2}
                                    >
                                      Fuzzed
                                    </Radio>
                                  </Space>
                                </Radio.Group>
                              </Card>
                            }
                            title={`Move ${item.verb} To`}
                            trigger="click"
                          >
                            <Button
                              icon={
                                <VerticalAlignMiddleOutlined
                                  style={{ color: "orange" }}
                                />
                              }
                            />
                          </Popover>,
                          <Popconfirm
                            onConfirm={() => {
                              const { selected } = this.state;
                              section.results = section.results.filter(
                                (i) => i.verb !== item.verb
                              );
                              this.setState({
                                selected,
                              });
                            }}
                            title="Are you sure？"
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              icon={<MinusOutlined style={{ color: "red" }} />}
                            />
                          </Popconfirm>,
                        ]}
                      >
                        <List.Item.Meta
                          avatar={item.voweled}
                          description={item.stripped}
                          title={item.verb}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              ))}
            </div>
          ) : (
            <p>Select Item to continue</p>
          )}
        </Modal>
      </div>
    );
  }
}
