import {
  List,
  Button,
  Skeleton,
  Drawer,
  Modal,
  Input,
  message,
  Popconfirm,
  Spin,
  BackTop,
  Select,
  notification,
  Avatar,
} from "antd";
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";
import api from "../utils/api";
import {
  PlusOutlined,
  MinusOutlined,
  ArrowUpOutlined,
  StarFilled,
  StarOutlined,
  TranslationOutlined,
  CheckOutlined,
  EditOutlined,
} from "@ant-design/icons";
import MoreDetails from "../components/MoreDetails";
import { io } from "socket.io-client";

const { Search } = Input;
const { Option } = Select;

export default class EnglishFamilies extends Component {
  state = {
    initLoading: true,
    loading: false,
    list: [],
    count: 10,
    showMore: false,
    selected: null,
    page: 1,
    newWord: false,
    searchResults: [],
    favorites: [],
    noScroll: false,
    lastpage: 0,
    showWordDetails: false,
    editing: [],
  };

  socket = null;

  componentDidMount() {
    this.socket = io({
      path: "/api/socket/",
      hostname:"http://app.gocognate.com"
    });
    this.socket.emit("user_joined", "editors");
    this.socket.on("joined", (data) => {
      notification.info({ message: "Joined Room" });
    });
    this.socket.on("data", (data) => {
      const { database } = data;
      this.setState({
        editing: database,
      });
    });

    this.getInitalData();
  }

  getInitalData = async () => {
    const { page, count } = this.state;
    const lastpage = parseInt(localStorage.getItem("lastpage")) || 1;
    const favorites = JSON.parse(localStorage.getItem("favorites")) || [];
    const data = await api.getEnglishFamilies(count, lastpage);

    const { results } = data.data;
    if (localStorage.getItem("favorites") === null) {
      localStorage.setItem("favorites", JSON.stringify([]));
      localStorage.setItem("lastpage", JSON.stringify(0));
    }
    this.setState({
      list: results,
      loading: false,
      initLoading: false,
      favorites,
      noScroll: false,
      page: lastpage,
    });
    window.scrollTo(0, document.body.scrollHeight);
  };
  onGoToPage = async () => {
    this.setState({
      loading: true,
      initLoading: true,
    });
    const { page, count } = this.state;
    const data = await api.getEnglishFamilies(count, page);
    const { results } = data.data;
    this.setState({
      list: results,
      loading: false,
      initLoading: false,
    });

    window.scrollTo(0, document.body.scrollHeight);
  };

  onLoadMore = async () => {
    let { page, count, noScroll } = this.state;
    page = parseInt(page);
    count = parseInt(count);
    if (noScroll) return;
    const data = await api.getEnglishFamilies(count, page + 1);
    localStorage.setItem("lastpage", page);
    const { results } = data.data;
    this.setState({
      list: results,
      loading: false,
      initLoading: false,
      page: page + 1,
    });
  };
  handleItemClicked = (item) => {
    console.log("ID:", item._id);
    this.socket.emit("is_editing", item._id);
    this.setState({
      selected: item,
      showMore: !this.state.showMore,
    });
  };
  addNewToFamily = () => {
    if (this.state.word && this.state.ratio) {
      message.success("Added Successfully");
      const { word, ratio, selected } = this.state;
      const id =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);

      selected.family[0].family.push({ word, ratio, id });
      this.setState({
        selected,
        newWord: false,
      });
    } else {
      message.error("Please fill all Fieleds");
    }
  };
  openModal = () => {
    this.setState({
      newWord: true,
    });
  };
  saveFamily = async () => {
    try {
      this.setState({ loading: true });
      const { selected } = this.state;
      const data = await api.saveEnglishSortedFamily(selected._id, selected);
      this.setState({ selected: null, loading: false, showMore: false });
      message.success("Added Successfully...");
    } catch (error) {
      message.error("Error Saving word :(");
    }
  };
  onSearch = async (term) => {
    const results = await api.searchEnglishFamilies(term);
    console.log(results.data);
    this.setState({
      searchResults: results.data.results,
    });
  };

  toggleFavorites = async (item) => {
    if (localStorage.getItem("favorites") === null) {
      localStorage.setItem("favorites", JSON.stringify([]));
    }

    const favorites = JSON.parse(localStorage.getItem("favorites"));
    if (favorites.find((e) => item._id === e._id)) {
      const rest = favorites.filter((e) => item._id !== e._id);
      localStorage.setItem("favorites", JSON.stringify(rest));
    } else {
      localStorage.setItem("favorites", JSON.stringify([item, ...favorites]));
    }
    this.setState({
      favorites: JSON.parse(localStorage.getItem("favorites")),
    });
  };

  isFavorite = (id) => {
    if (this.state.favorites.find((item) => item._id === id)) return true;
    return false;
  };

  showSelected = async () => {
    const { page, count } = this.state;
    this.setState({
      loading: true,
      initLoading: true,
      noScroll: true,
    });
    const data = await api.getEnglishFamilies(10000, page);
    const { results } = data.data;
    if (localStorage.getItem("favorites") === null) {
      localStorage.setItem("favorites", JSON.stringify([]));
    }
    const favorites = JSON.parse(localStorage.getItem("favorites") || []);
    this.setState({
      list: results.filter((i) => favorites.find((e) => e._id === i._id)),
      loading: false,
      initLoading: false,
      favorites,
    });
  };

  showTranslation = async (word) => {
    const { data } = await api.getGoogle(word, "ar");
    const def = await api.getDefinition(word);
    console.log();
    notification.open({
      message: (
        <p style={{ backgroundColor: "#333", color: "#fff" }}>
          Translationg: {<strong>{word}</strong>}
          <p>
            Google Translation : <strong>{data.text}</strong>
          </p>
        </p>
      ),
      description: (
        <div>
          {`${def.data?.data?.definitions?.pons}`.length > 500 ? (
            <details>
              <summary>Definitions</summary>
              <p>{def.data?.data?.definitions?.pons}</p>
            </details>
          ) : (
            <p>{def.data?.data?.definitions?.pons}</p>
          )}
          <table>
            <tbody>
              <tr>
                <td>Language</td>
                <td>Text</td>
              </tr>
              {def.data?.data?.translations.map((item) => (
                <tr>
                  <>
                    <td>[{item.lang}]</td>
                    <td>
                      <strong>{item.value}</strong>
                    </td>
                  </>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ),
      style: { backgroundColor: "#333", color: "#fff" },
      placement: "topRight",
    });
  };

  render() {
    const { initLoading, loading, list } = this.state;
    const options = this.state.searchResults.map((d) => (
      <Option key={d.word}>{d.word}</Option>
    ));

    return (
      <div style={{ background: "#ccc" }}>
        <h1 style={{ padding: 20 }}>English Families</h1>
        <h4 style={{ padding: 20 }}>Current Page:{this.state.page}</h4>
        <div style={{ paddingLeft: 50, paddingRight: 50 }}>
          <div>
            <Input
              min={1}
              max={15000}
              value={this.state.page}
              style={{ width: "25%" }}
              placeholder="Go To Page...."
              type="number"
              onChange={async (e) => this.setState({ page: e.target.value })}
              addonAfter={
                <Button onClick={this.onGoToPage} block>
                  Go
                </Button>
              }
            />
            <Select
              placeholder="Search"
              showSearch
              style={{ width: "25%" }}
              onChange={(val) => {
                const selected = this.state.searchResults.find(
                  (item) => item.word === val
                );
                this.setState({
                  showMore: true,
                  selected,
                });
              }}
              onSearch={this.onSearch}
            >
              {options}
            </Select>
            <Button loading={this.state.loading} onClick={this.showSelected}>
              Show Marked
            </Button>
            <Button loading={this.state.loading} onClick={this.getInitalData}>
              Reset
            </Button>
            {/* {JSON.str} */}
          </div>
          <InfiniteScroll
            initialLoad={this.state.initLoading}
            pageStart={0}
            loadMore={(page) => this.onLoadMore()}
            hasMore={!this.state.loading}
            useWindow={true}
          >
            <List
              className="demo-loadmore-list"
              loading={initLoading}
              itemLayout="horizontal"
              dataSource={list}
              renderItem={(item) => (
                <List.Item
                  style={{ cursor: "pointer" }}
                  extra={[
                    <Button
                      type="text"
                      onClick={() => this.toggleFavorites(item)}
                      icon={
                        this.isFavorite(item._id) ? (
                          <StarFilled style={{ fontSize: 24, color: "red" }} />
                        ) : (
                          <StarOutlined
                            style={{ fontSize: 24, color: "black" }}
                          />
                        )
                      }
                    ></Button>,
                  ]}
                >
                  <Skeleton
                    avatar
                    title={false}
                    loading={
                      this.state.loading ||
                      this.state.editing?.indexOf(item._id) > -1
                        ? true
                        : false
                    }
                    active
                    paragraph
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar style={{ backgroundColor: "#333" }}>
                          {item.hasOwnProperty("updated_at") ? (
                            <CheckOutlined style={{ color: "yellow" }} />
                          ) : (
                            "None"
                          )}
                        </Avatar>
                      }
                      onClick={() => this.handleItemClicked(item)}
                      title={<p style={{ fontSize: "1.2rem" }}>{item.word}</p>}
                      description={`Family: ${item.family[0].family.length} Extra: ${item.family[0].extra.length}`}
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>
        <BackTop>
          <ArrowUpOutlined size={44} />
        </BackTop>
        <Drawer
          visible={this.state.showMore}
          closable={true}
          onClose={() => {
            console.log("Selected", this.state.selected._id);
            this.socket.emit("finish_editing", this.state.selected._id);
            this.setState({ showMore: false, selected: null });
          }}
          width={"50%"}
          footer={
            <Button
              onClick={this.saveFamily}
              loading={this.state.loading}
              block
              style={{ backgroundColor: "#222f3e", color: "#fff" }}
            >
              Save
            </Button>
          }
        >
          {this.state.selected ? (
            <div>
              <h1>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.state.selected.link}
                >
                  {this.state.selected.word}
                </a>
              </h1>
              {/* More Info */}
              {this.state.showWordDetails && (
                <MoreDetails lang="ar" selected={this.state.selected} />
              )}

              <Button
                type="text"
                onClick={() => this.toggleFavorites(this.state.selected)}
                icon={
                  this.isFavorite(this.state.selected._id) ? (
                    <StarFilled style={{ fontSize: 24, color: "red" }} />
                  ) : (
                    <StarOutlined style={{ fontSize: 24, color: "black" }} />
                  )
                }
              ></Button>

              <List
                header={
                  <h4 style={{ color: "#ff6b6b" }}>
                    Family
                    <Button
                      shape="circle"
                      onClick={this.openModal}
                      type="link"
                      icon={<PlusOutlined />}
                    />
                    <Button
                      shape="circle"
                      onClick={() => {
                        console.log(this.state.showWordDetails);
                        this.setState({
                          showWordDetails: !this.state.showWordDetails,
                        });
                      }}
                      type="link"
                      icon={
                        <TranslationOutlined
                          style={{
                            color: this.state.showWordDetails ? "red" : "blue",
                          }}
                        />
                      }
                    />
                  </h4>
                }
                dataSource={this.state.selected.family[0].family}
                renderItem={(word) => (
                  <List.Item
                    actions={[
                      <Button
                        onClick={() => {
                          const selected = this.state.selected;
                          selected.family[0].family =
                            selected.family[0].family.filter(
                              (item) => item.word !== word.word
                            );
                          selected.family[0].extra.unshift(word);

                          this.setState({
                            selected,
                          });
                        }}
                        type="primary"
                        style={{ backgroundColor: "red", borderColor: "red" }}
                        icon={<MinusOutlined />}
                      >
                        Remove
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      title={
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`https://www.google.com/search?q=${word.word}`}
                        >
                          {word.word}
                        </a>
                      }
                    />
                  </List.Item>
                )}
              />

              {/* Drawer Family  Exrta*/}
              <List
                header={<h4 style={{ color: "#10ac84" }}>Extra</h4>}
                dataSource={this.state.selected.family[0].extra}
                renderItem={(word) => (
                  <List.Item
                    actions={[
                      <Button
                        onClick={() => {
                          const selected = this.state.selected;
                          const family = selected.family[0].family;
                          selected.family[0].extra =
                            selected.family[0].extra.filter(
                              (item) => item.word !== word.word
                            );
                          family.push(word);
                          this.setState({
                            selected,
                          });
                        }}
                        type="primary"
                        icon={<PlusOutlined />}
                      >
                        Add To Family
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      title={
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`https://www.google.com/search?q=${word.word}`}
                        >
                          {word.word}
                        </a>
                      }
                    />
                  </List.Item>
                )}
              />
            </div>
          ) : null}
        </Drawer>
        <Modal
          title="Add New Word"
          visible={this.state.newWord}
          onOk={this.addNewToFamily}
          onCancel={() => this.setState({ newWord: false })}
        >
          <Input
            onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
            placeholder="Word"
            name="word"
          />
          <Input
            onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
            max={100}
            min={0}
            placeholder="Ratio to original word"
            type="number"
            name="ratio"
          />
        </Modal>
      </div>
    );
  }
}
