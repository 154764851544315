import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { Icon } from '@material-ui/core';
import TabsFamily from './TabsFamily';
import {Loader} from 'tabler-react'


const classes = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function StoreDialog(props) {
  return (
    <div>
      <Dialog fullScreen  open={props.open} onClose={props.handleClose} TransitionComponent={Transition}>
            <div className="appBar">
              <Icon onClick={props.handleClose} className="appBarIcon">close</Icon>
              <div className="appBarLoading">{props.store.loading ? <Loader  /> : null}</div>
            </div>
               <TabsFamily handleClose={props.handleClose} store={props.store} />
      </Dialog>
    </div>
  );
}
