import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import {List} from 'tabler-react';
import { Icon } from '@material-ui/core';
import api from '../utils/api';
import MyCard from '../components/MyCard';


class Settings extends Component {
    componentDidMount() {
        this.props.store.fetchSettings();
    }
    delteItem = async (term, item) => {
        const response = await api.deleteSetting(term, item);
        this.props.store.setSettings(response.data);
    }
    render() {
        return (
            <div style={{padding: "20px"}} >
                <h1>Settings</h1>
                <MyCard title="Prefixes & Suffixes" active={this.props.store.loading}>
                    <div style={{padding: "20px", width: '50%', float:'left'}}>
                        <h3>Prefix:</h3>
                        <List.Group>
                            {this.props.store.settings.prefixes ?
                            this.props.store.settings.prefixes.map(item => (
                                <List.GroupItem>
                                    {item}
                                    <Icon onClick={() =>  this.delteItem('prefix', item)} className="listItemIconRight">delete</Icon>
                                </List.GroupItem>
                                ))
                                :null}
                        </List.Group>
                    </div>
                    <div style={{padding: "20px", width: '50%', float: 'right'}}>
                        <h3>Suffix:</h3>
                        <List.Group>
                            {this.props.store.settings.suffixes ?
                            this.props.store.settings.suffixes.map(item => (
                                <List.GroupItem>
                                    {item}
                                    <Icon onClick={() =>  this.delteItem('suffix', item)} className="listItemIconRight">delete</Icon>
                                </List.GroupItem>
                                ))
                                :null}
                        </List.Group>
                    </div>
                </MyCard>
            </div>
        )
    }
}

export default inject('store')(observer(Settings));