import React, { Component } from 'react'
import {NavLink} from 'react-router-dom'

export default class SidebarWord extends Component {
    render() {
        return (
            <div className="sidebar-word">
                <NavLink
                    to={`/${this.props.link}/#${this.props.word}`}
                    onClick={() => this.props.wordClicked(this.props.word)}
                    activeClassName="selected"
                    >
                        {this.props.word}
                    </NavLink>
            </div>
        )
    }
}
