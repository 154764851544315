import React, { Component } from 'react'
import { Button } from '@material-ui/core';
import {Card, Grid} from 'tabler-react';

import {withRouter} from 'react-router-dom'

class Overview extends Component {
    save = async () => {
        try {
            console.log("saving...");
            const response = await this.props.store.saveFamily(this.props.store.store);
            this.props.store.toggleNote(response.data.message);
            this.props.handleClose();
            
        } catch (error) {
            console.log(error);
        }
    }
    render() {
        const {store} = this.props.store
        return (
            <div style={{margin: '0 auto'}}>
                <h3>Overview</h3>
                <h4>Selected Word: {this.props.store.selectedWord}</h4>
                <p>Select your data from other tabs..</p>
                <div style={{padding: "20px"}}>
                    {
                        <Grid.Row cards deck>
                            <Grid.Col md={12}>
                                <Card title="Added Value:">
                                    <p>{store.word}</p>
                                    <p>{store.postion}</p>
                                    <p>{store.pronounciation}</p>
                                    <p>{store.arabic_pronounciation}</p>
                                    <p>{store.rythem}</p>
                                    <p>{store.origin}</p>
                                    <p>{store.bedeutungsgleich}</p>
                                    <p> <audio src={store.sound} controls /></p>
                                    <p>{store.translation && JSON.stringify(store.translation)}</p>
                                    {store.examples && store.examples.map(item=>(<p>{item}</p>))}
                                    {store.synome && store.synome.map(item=>(<p>{item}</p>))}
                                    {store.phonetik && store.phonetik.map(item=>(<p>{item}</p>))}
                                    {store.hyphenation && store.hyphenation.map(item=>(<p>{item}</p>))}
                                    {store.dialekte && store.dialekte.map(item=>(<p>{item}</p>))}
                                    <p>{store.etymologie}</p>
                                    <p>{JSON.stringify(store.antonyme)}</p>
                                    <p>{JSON.stringify(store.phraseologismen)}</p>
                                    <p>{JSON.stringify(store.paromien)}</p>
                                    <p>{JSON.stringify(store.signifikante)}</p>
                                    <p>{JSON.stringify(store.derivate)}</p>
                                    <p>{JSON.stringify(store.grammatische)}</p>
                                    <p>{JSON.stringify(store.derivate)}</p>

                                </Card>
                            </Grid.Col>
                      </Grid.Row>
                            
                    }
                </div>
                <Button color="primary" style={{backgroundColor:"#ddd"}} fullWidth onClick={this.save}>Save</Button>
            </div>
        )
    }
}


export default withRouter(Overview)