import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import auth from '../utils/storage';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      auth.isLoged === true
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />
  )

export default PrivateRoute;