import React, {Component} from 'react'
import '../styles/filter.css'
import Family from '../components/Family';
import { Button } from '@material-ui/core';
import SidebarWord from '../components/SidebarWord';
import { inject, observer } from 'mobx-react';
class Filter extends Component {


    wordClicked = (word) => {
        this.props.store.getWordFamily(word);
    }

    componentDidMount() {
        this.props.store.getWords();
    }

    addMember = () => {

    }

    showmore = () => {
        this.props.store.showMore()
    }

  render() {
    const {words} = this.props.store
    return (
      <React.Fragment>
        <div className="grid-container">
          <div className="main">
            <Family store={this.props.store} />
            {this.props.store.loading ? null: null}
          </div>
          <div className="sidebar">
            <div id="top"></div>
            {words && words.map(word => (
                <SidebarWord link={'outside'}  key={Math.random()} word={word.word} wordClicked={this.wordClicked} />
            ))}
            <Button style={{color: "yellow"}} fullWidth onClick={this.showmore}>Show More</Button>
          </div>
        </div>
      </React.Fragment>
    )
  }
}


export default inject('store')(observer(Filter));