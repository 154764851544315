import React from 'react'
import {List} from 'tabler-react';

export default function EnglishItem(props) {
  return (
    <div key={props.id} className="family-word-item">
      <List.GroupItem action active>
        <p onClick={() => props.handleClickOpen(props.word)}>
          {props.word}
          <span className="family-word-item-ratio">
            {props.ratio}
          </span>
        </p>
      </List.GroupItem>
    </div>
  )
}