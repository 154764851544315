import React, {Component} from 'react'
import {LoginPage} from 'tabler-react'
import api from '../utils/api';
import auth from '../utils/storage';
import {withRouter} from 'react-router-dom'


class Login extends Component {
    state = {
        error: null
    }

  onSubmit = async (event) => {
    event.preventDefault();
    const username = event.target.elements[0].value.trim();
    const password = event.target.elements[1].value.trim();
    try {
      if(username.length > 4 && password.length >= 4){
        const results = await api.loginUser({username,password});
        auth.login(results.data);
        api.setAccessToken();
        this.props.history.push('/')
      }else {
        this.setState({
          error: "username and password most be over 4 characters..."
      })
      }
    } catch (error) {
      console.log('err', error)
        this.setState({
            error: "username or password are incorrect..."
        })
    }
}

  render() {
    return (
      <div>
        <LoginPage strings={{emailLabel:"Username", emailPlaceholder:"Enter Username"}}  onSubmit={this.onSubmit} />
          {this.state.error ?
            <div className="login-error">
              {this.state.error}
            </div>
            : null}
      </div>
    )
  }
}


export default withRouter(Login);