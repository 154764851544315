import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import SidebarWord from '../components/SidebarWord';
import Family from '../components/Family';
import { Button } from '@material-ui/core';


class OutsideFilter extends Component {

    wordClicked = (word) => {
        this.props.store.getWordFamily(word);
    }

    componentDidMount() {
        this.props.store.getWords();
    }

    showmore = () => {
        this.props.store.showMore()
    }

    render() {
        const {words} = this.props.store
        return (
            <div  className="page-container">
                {
                    this.props.store.loading 
                    ? null
                    : null
                }
                <div className="sidebar">
                    <div id="top"></div>
                    {words && words.map(word => (
                        <SidebarWord key={word.id} word={word.word} wordClicked={this.wordClicked} />
                    ))}
                    <Button style={{color: "yellow"}} fullWidth onClick={this.showmore}>Show More</Button>
                </div>
                <Family store={this.props.store} />
            </div>
        )
    }
}
// use mobx without docerators
export default inject('store')(observer(OutsideFilter));