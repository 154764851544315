import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';

export default function WordCheckbox(props) {
    const handleIT = (e) => {
        if(props.handleChange) {
            const title = props.title || null
            props.handleChange(e.target.value,e.target.checked, title);
        }
        return
    }
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            // checked={props.checked}
            onChange={handleIT}
            value={props.value}
            color="primary"
          />
        }
        label={props.label}>
      </FormControlLabel>
        {props.children}
    </div>
  );
}
