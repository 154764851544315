import React, { Component } from 'react'
import WordCheckbox from './WordCheckbox';
import TabsItem from './TabsItem';

export default class Wiki extends Component {
    state = {}
    ifContentArray = (value, status, title) => {
        const data = this.props.store.store[title] || [];
        if(status) {
            this.props.store.setStore(title, [...data, value]);
        }else {
            this.props.store.setStore(title,  data.filter(item => item !== value));
        }
    }

    ifContnetText = (value, status, title) => {
        console.log('value', value)
        console.log('value', status)
        console.log('value', title)
        if(status) {
            this.props.store.setStore(title, value);
        }else {
            this.props.store.setStore(title, '');            
        }
    }

    render() {
        const {wiki} = this.props.store;
        return (
            <div>
                <h2>Wiktionary</h2>
                {Object.keys(wiki).length > 0  ? 
                    <div style={{padding: '10px'}}>
                        {wiki.data.phonetik
                                ? (
                                    <div>
                                        <WordCheckbox handleChange={this.ifContnetText} title="sound" value={wiki.data.phonetik.sound} label="" ><audio controls src={`https://${wiki.data.phonetik.sound}`} /></WordCheckbox>
                                        <p className="phonetik-p">
                                            IPA: <WordCheckbox handleChange={this.ifContnetText} title="pronounciation"  value={wiki.data.phonetik.ipa} label={wiki.data.phonetik.ipa} />
                                        </p> 
                                        <p className="phonetik-p">
                                            Reime: <WordCheckbox value={wiki.data.phonetik.reime} handleChange={this.ifContnetText} title="rythem" label={wiki.data.phonetik.reime} />
                                        </p> 
                                        <p className="phonetik-p">
                                            position: <WordCheckbox title="postion" handleChange={this.ifContnetText} value={wiki.postion} label={wiki.postion} />
                                        </p>
                                        <p className="phonetik-p">
                                            {wiki.data 
                                                ? <WordCheckbox label={wiki.data['semantik'].content.join(' ')} title={"bedeutungsgleich"} value={wiki.data['semantik'].content.join(' ')} handleChange={this.ifContnetText} />
                                                : null}
                                        </p>
                                    </div>
                                )
                                : <p>No Audio sample was found...</p>}
                        {
                            Object.keys(wiki.data).map(elm => (
                                wiki.data[elm] !==null && wiki.data[elm].title !== "Bedeutungen" ? (
                                        <div>
                                            <h4>{wiki.data[elm].title}</h4>
                                            {
                                                Array.isArray(wiki.data[elm].content) 
                                                ? wiki.data[elm].content.map(cont => (
                                                    <WordCheckbox title={elm} handleChange={this.ifContentArray} label={cont} value={cont} />
                                                ))
                                                : <WordCheckbox title={elm} handleChange={this.ifContnetText} label={wiki.data[elm].content} value={wiki.data[elm].content} />
                                            }
                                        </div>

                                ) : null
                            ))
                        }
                    </div>
                : <p>No Wiki results...</p>}
            </div>
        )
    }
}
