import React from 'react';
import PropTypes, { object } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TabsItem from './TabsItem';
import Wiki from './Wiki';
import Duden from './Duden';
import Translation from './Translation';
import Arabdict from './Arabdict';
import Examples from './Examples';
import {Loader} from 'tabler-react';
import Overview from './Overview';
import Synome from './Synome';
import Notes from './Notes';
import api from '../utils/api';
import Axios from 'axios';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TabsFamily(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);




  function handleChange(event, newValue) {
    setValue(newValue);
  }
  const {duden, wiki, google, arabdict} = props.store;
  const  displayWiki = () => {
    let wikis = JSON.parse(JSON.stringify(props.store.wiki))
    delete wikis.postion;
    delete wikis.word;
    return wikis
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab onClick={() =>  props.store.setDuden(props.store.selectedWord) } label="Duden" {...a11yProps(1)} />
          <Tab label="Synome" {...a11yProps(2)} />
          <Tab onClick={() =>  props.store.setWiki(props.store.selectedWord) } label="Wiki" {...a11yProps(3)} />
          <Tab onClick={() =>  props.store.translateGoogle(props.store.selectedWord) } label="Translation" {...a11yProps(4)} />
          <Tab onClick={() =>  props.store.getArabDict(props.store.selectedWord) } label="Arabdict" {...a11yProps(5)} />
          <Tab onClick={() =>  props.store.getExamples(props.store.selectedWord) } label="Examples" {...a11yProps(6)} />
          <Tab onClick={() =>  props.store.getDefinition() } label="Notes" {...a11yProps(7)} />
        </Tabs>
      </AppBar>


      <TabPanel value={value} index={0}>
        <Overview handleClose={props.handleClose}  store={props.store} />
      </TabPanel>

      <TabPanel value={value} index={1}>
          <Duden store={props.store} />
      </TabPanel>

      <TabPanel value={value} index={2}>
          <h2>Synonyme</h2>
          {Object.keys(duden).length > 0  ?
            <div style={{padding: '10px'}}>
              <Synome store={props.store} />
            </div>
          : <p>No Duden results...</p>}
      </TabPanel>
      <TabPanel value={value} index={3}>
            <Wiki store={props.store} displayWiki={displayWiki} />
      </TabPanel>

      <TabPanel value={value} index={4}>
          <Translation store={props.store} />
      </TabPanel>

      <TabPanel value={value} index={5}>
            <Arabdict store={props.store} />
      </TabPanel>

      <TabPanel value={value} index={6}>
            <Examples store={props.store} />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Notes />
      </TabPanel>
    </div>
  );
}
