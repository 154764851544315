import Axios from "axios";
import auth from './storage';

class Api {
    constructor() {
        // this.url = `https://langlastnode.herokuapp.com`;
        this.url = "http://138.68.109.234/api/v1";
        // this.url = `http://localhost:4000`;
        // this.fetch = `https://arabdict.herokuapp.com`
        this.fetch = "http://167.99.241.90:5000";
        // this.fetch = "http://localhost:5000";
        this.client = Axios.create({ baseURL: this.url });
        this.setAccessToken();
    }

    setAccessToken () {
        const token = auth.token();
        if (token) {
          this.client.defaults.headers.Authorization = `Bearer ${token}`
        } else if (this.client.defaults.headers.Authorization) {
          delete this.client.defaults.headers.Authorization
        }
      }


    getWords = (count=0) => this.client.get(`${this.url}/words/count/${count}`);
    getFamily = (word) => this.client.get(`${this.url}/words/${word}`);
    useSearch = (word) => this.client.get(`${this.url}/words/search/${word}`);
    
    // Filter Methods
    getDuden = (word) => this.client.get(`${this.fetch}/duden/${word}`);
    getWiki = (word) => this.client.get(`${this.fetch}/newwiki/${word}`);
    getGoogle = (word, target) =>this.client.post(`https://arabdict.herokuapp.com/googletrans/${target}`, {data: word})
    getDict = (word) => this.client.get(`${this.fetch}/translate/${word}`)
    getExamples = (word) => this.client.get(`${this.fetch}/examples_/${word}`)
    getYandex = (word) => Axios.get(`${this.fetch}/api/yandex/${word}/ar`)
    getDefinition = (word) => Axios.get(`https://app.deya.io/memerize/freedict?word=${word}`);
  
    // Settings Page
    fetchSettings = () => this.client.get(`${this.url}/settings`)

    // Settings actions
    deleteSetting = (term, item) => this.client.delete(`${this.url}/settings/${term}/${item}`)

    // Save word
    saveWord = (body) =>  this.client.post(`${this.url}/members/save`, body);

    // Login
    loginUser = (body) => this.client.post(`${this.url}/users/login`, body);

    // Saved words
    savedWords = () => this.client.get(`${this.url}/words/stored/family`);

    // Families
    storeFamily = (body) => this.client.post(`${this.url}/members`, body);
    getFamilyByWord = (word) => this.client.get(`${this.url}/members/${word}`);

    // statistics
    statsFamilies = () => this.client.get(`${this.url}/members/stats/families`)
    statsMembers  = () => this.client.get(`${this.url}/members/stats/members`)

    // English Filter Function
    getEnglishWords = (count=100) => this.client.get(`${this.url}/english/count/${count}`);
    getEnglishWordDetails = (word) => this.client.get(`${this.fetch}/english/${word}`);
    getEnglishWordFamily = (word) => this.client.get(`${this.url}/english/${word}`);
    getSearchResults = (word) => this.client.get(`${this.url}/english/search/${word}`);

    // new families method
    getFamilies = (count, page) => this.client.get(`${this.url}/families?count=${count}&page=${page}`);
    saveSortedFamily = (id, body) => this.client.post(`${this.url}/families/${id}`, body);
    searchFamilies = (term) => this.client.get(`${this.url}/families/search/${term}`);


    // new English families method
    
    getEnglishFamilies = (count, page) => this.client.get(`${this.url}/families/english?count=${count}&page=${page}`);
    saveEnglishSortedFamily = (id, body) => this.client.post(`${this.url}/families/english/${id}`, body);
    searchEnglishFamilies = (term) => this.client.get(`${this.url}/families/english/search/${term}`);



    // store Members New Method
    storeVerbsMembers = (body) => this.client.post(`${this.url}/verbs/save`, body);
    getVerbsAdded = (limit, next) => this.client.get(`${this.url}/verbs/all/?limit=${limit}&next=${next}`);
    deleteMemberInVerbs = (id, word) => this.client.post(`${this.url}/verbs/delete/${id}/${word}`)
    deleteVerb = (id) => this.client.post(`${this.url}/verbs/deleteverb/${id}`)


    // connecting languages
    getConnectionData = () => this.client.get(`${this.url}/connect`);
    saveConnectionData = (data) => this.client.post(`${this.url}/connect`, {data})
    getConnectionAddDetails = (word, locale) =>this.client.post(`${this.url}/connect/change`, {word, locale})

    // Admin Api
    getAdminStats = () => this.client.get(`${this.url}/stats`)


  } 

export default new Api();