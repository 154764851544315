import React, { Component } from 'react'
import StoreDialog from './StoreDialog';
import { Icon, Dialog, DialogTitle, DialogContent, ListItem, ListItemText, Checkbox, ListItemIcon } from '@material-ui/core';
import { List, Form, Button } from 'tabler-react';
import { observer, inject } from 'mobx-react';
import api from '../utils/api';
import { message } from 'antd';

class Family extends Component {

    state = {
        dialogOpen: false,
        show_family: false,
        addmember: false,
        member: "",
        ratio: 0,
        showMainFamily: false,
        text: "",
        members: []
    }

    handleClickOpen = (word) => {
        this.setState({
            dialogOpen: true
        })
        this.props.store.setSlectedWord(word);
        this.props.store.setStore('word', word);
        this.props.store.setStore('origin', this.props.store.family.origin);
    }

    show_family = async () => {
        await this.props.store.getStored();
        this.setState({
            show_family: true
        })
    }

    showAddMember = () => {
        this.setState({ addmember: true });
    }

    handleClose = () => {
        this.setState({
            dialogOpen: false
        })
        this.props.store.duden = []
        this.props.store.wiki = []
        this.props.store.my_examples = []
        this.props.store.arabdict = []
        this.props.store.google = []
        this.props.store.yandex = []
        this.props.store.definition = []
        this.props.store.resetStore();
    }
    addMemberToFamily = () => {
        this.props.store.addOwnMemberToFamily(this.state.member, this.state.ratio);
        this.setState({
            addmember: false,
            member: "",
            ratio: 0,
        })
    }

    componentDidMount() {
        // this.refs.refOnScroll.addEventListener("scroll", () => {
        //     if (
        //         this.refs.refOnScroll.scrollTop + this.refs.refOnScroll.clientHeight >=
        //         this.refs.refOnScroll.scrollHeight
        //     ) {
        //         const count = this.props.store.word_family.detail.length + 25;

        //         this.props.store.loadMoreFamily(count);
        //     }
        // });
    }
    addMainWord = () => {
        this.setState({
            showMainFamily: true
        })
    }
    assignMainFamilyText = () => {
        this.props.store.setStore('text', this.state.text);
        this.setState({ showMainFamily: false });
    }


    onWordSelected = (status, word) => {
        const { members } = this.state
        members.find(i => i.word === word.word)
        if (status && !members.find(i => i.word === word.word)) {
            members.push(word)
            this.setState({
                members
            })
        } else {
            this.setState({
                members: members.filter(item => item.word !== word.word)
            })
        }
    }

    handleSave = async () => {
        try {
            const { origin, striped } = this.props.store.family;            
            const { members } = this.state

            const response = await api.storeVerbsMembers({ origin, striped, members, verb: this.state.text })
            message.success(response.data.message, 3)

            this.setState({
                members: [],
            })
            this.props.store.reset()

        } catch (error) {
            console.log(error)
            message.error("Error Adding Family", 3)
        }

    }

    render() {
        const { words, word_family } = this.props.store
        return (
            <div className="family">
                <div className="show_added_family" >
                    {word_family.detail && word_family.detail.length > 0 && (
                        <Checkbox
                            style={{ color: "#fff", marginTop: "10px", marginLeft: "5px", padding: 0 }}
                            onChange={(e) => this.setState({ members: e.target.checked ? word_family.detail : [] })}
                            tabIndex={-1}
                        />
                    )}
                    <Icon onClick={this.addMainWord} style={{ color: "#fff", marginTop: "10px", marginLeft: "5px" }}>attach_file</Icon>
                    <Icon onClick={this.show_family} style={{ color: "#fff", marginTop: "10px", marginLeft: "5px" }}>store</Icon>
                    <Icon onClick={this.showAddMember} style={{ color: "#fff", marginTop: "10px", marginLeft: "5px" }}>add</Icon>
                    {
                        this.state.members.length > 0 ? (
                            <Icon onClick={this.handleSave} style={{ color: "red", marginTop: "10px", marginLeft: "5px" }}>save</Icon>
                        ) : null
                    }
                </div>
                <div ref="refOnScroll"
                    style={{ height: "85vh", overflow: "auto", width: "98%", marginTop: 20 }}>
                    <List>
                        {word_family.detail && word_family.detail.map(i => (
                            <ListItem style={{ backgroundColor: "#222", fontWeight: "bold", color: "#fff" }}>
                                <ListItemIcon>
                                    <Checkbox
                                        onChange={(e) => this.onWordSelected(e.target.checked, i)}
                                        checked={!!this.state.members.find(item => item.word === i.word)}
                                        edge="start"
                                        style={{ color: "#fff" }}
                                        tabIndex={-1}
                                    // disableRipple
                                    // inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText color={"#333"} style={{ fontWeight: "bold" }} id={i.id} primary={`${i.word}`} />
                            </ListItem>
                        ))}
                    </List>
                    {/* <List.Group>
                         {word_family.detail && word_family.detail.map(i => (
                             <div key={i.id} className="family-word-item" >
                                <List.GroupItem action active>
                                    <p onClick={() => this.handleClickOpen(i.word)}>
                                        {i.word}
                                        <span className="family-word-item-ratio">
                                            {i.ratio}
                                        </span>
                                    </p>
                                </List.GroupItem>
                            </div>
                         ))}
                         {(word_family.detail && word_family.detail.length) === 0 ? <div className="family-not-found"><p>مقطوع من شجرة</p></div> : null }
                         </List.Group> */}
                </div>
                <StoreDialog handleClose={this.handleClose} open={this.state.dialogOpen} store={this.props.store} />
                {
                    this.state.show_family ? (
                        <Dialog style={{ width: "100%" }} open={this.state.show_family} aria-labelledby="simple-dialog-title" onClose={() => this.setState({ show_family: false })}>
                            <DialogTitle id="simple-dialog-title">Show Added Words</DialogTitle>
                            <DialogContent dividers={true} >
                                <List>
                                    {
                                        this.state.hasOwnProperty('members') ?
                                            this.state.members.map(item => (
                                                <ListItem>
                                                    <ListItemText primary={item.word} />
                                                </ListItem>
                                            )) : <p>No Family added...</p>
                                    }
                                </List>
                            </DialogContent>
                        </Dialog>
                    ) : null
                }
                {
                    this.state.addmember && this.props.store.family.detail ? (
                        <Dialog style={{ width: "100%" }} open={this.state.addmember} aria-labelledby="simple-dialog-title" onClose={() => this.setState({ addmember: false })}>
                            <DialogTitle id="simple-dialog-title">Add new member</DialogTitle>
                            <DialogContent dividers={true} >
                                <Form.Group>
                                    <Form.Label>Word</Form.Label>
                                    <Form.Input onChange={(e) => this.setState({ member: e.target.value })} placeholder="Word To Insert..." />
                                    <Form.Label>Ratio</Form.Label>
                                    <Form.Input onChange={(e) => this.setState({ ratio: e.target.value })} placeholder="Ratio..." type="number" />
                                    <Form.Label></Form.Label>
                                    <Button onClick={this.addMemberToFamily} color="#333">Add</Button>
                                </Form.Group>
                            </DialogContent>
                        </Dialog>
                    ) : null
                }

                {
                    this.state.showMainFamily && this.props.store.family.detail ? (
                        <Dialog style={{ width: "100%" }} open={this.state.showMainFamily} aria-labelledby="simple-dialog-title" onClose={() => this.setState({ showMainFamily: false })}>
                            <DialogTitle id="simple-dialog-title">Set The New Main Word</DialogTitle>
                            <DialogContent dividers={true} >
                                <Form.Group>
                                    <Form.Label>Word</Form.Label>
                                    <Form.Input onChange={(e) => this.setState({ text: e.target.value })} placeholder="Word To Set..." />
                                    <Button onClick={this.assignMainFamilyText} color="#333">Add</Button>
                                </Form.Group>
                            </DialogContent>
                        </Dialog>
                    ) : null
                }

            </div>
        )
    }
}

export default inject('store')(observer(Family))