import React, {Fragment} from 'react';
import './App.css';
import Home from './pages/Home';
import {Route} from 'react-router-dom'
import OutsideFilter from './pages/OutsideFilter';
import "tabler-react/dist/Tabler.css";
import Navigation from './components/Navigation';
import { Provider } from 'mobx-react';
import {WordStore, EnglishStore} from './stores';
import Settings from './pages/Settings';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/Login';
import Filter from './pages/Filter';
import EnglishFilter from './pages/EnglishFilter';
import Families from './pages/Families';
import EnglishFamilies from './pages/EnglishFamilies';
import Combine from './pages/Combine';
import Admin from './pages/Admin';
import ProfilePage from './pages/ProfilePage';


function App() {
  return (
    <Fragment>
      <Provider store={WordStore} english={EnglishStore}>
        <PrivateRoute path='/' component={Navigation} />
        <PrivateRoute path='/admin' component={Admin} />
        <Route exact path="/login" component={Login} />
        <PrivateRoute path="/" exact component={Home} />
        <PrivateRoute path="/settings" exact component={Settings} />
        <PrivateRoute path="/outside" exact component={Filter} />        
        <PrivateRoute path="/english" exact component={EnglishFilter} />        
        <PrivateRoute path="/families" exact component={Families} />
        <PrivateRoute path="/families/english" exact component={EnglishFamilies} />
        <PrivateRoute path="/families/connect" exact component={Combine} />
        <PrivateRoute path="/profile" exact component={ProfilePage} />

      </Provider>
    </Fragment>
  );
}

export default App;
