import React, { Component } from 'react'
import {Card, Dimmer} from 'tabler-react'
export default class MyCard extends Component {
    render() {
        return (
            <div>
                <Card isCollapsed={this.props.closed} title={this.props.title} isClosable={this.props.isClosable || true} isCollapsible={this.props.isCollapsible || true}>
                    <Card.Body>
                        <Dimmer active={this.props.active} loader={this.props.active}>
                            {this.props.children}
                        </Dimmer>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}
