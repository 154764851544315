import React, { Component } from 'react'
import WordCheckbox from './WordCheckbox';
import {Form,Button,Icon} from 'tabler-react'

export default class Translation extends Component {
    state = {
        translation: [],
        input:'',
        err: null
    }

    addTranslation = (e) => {
        const {input, translation} = this.state;
        if(this.state.input.length > 2) {
            translation.push(input)
            this.setState({
                translation,
                input: '',
                err: null
    
            })
            return this.handleChanges(...this.state.translation, true, 'translation');
        }
        return this.setState({
                err: "Cant be empty"
        })
    }

    removeTranslation = (item) => {
        console.log('item', item)
        let data = this.state.translation.filter(i => i !== item)
        this.setState({
            translation: data,
            input: ''
        })
    }

    handleChanges = (value, status, title) => {
        const data = this.props.store.store[title] || [];
        if(status) {
            this.props.store.setStore(title, [...data, value]);
        }else {
            this.props.store.setStore(title,  data.filter(item => item !== value));
        }
    }

    ifContnetText = (value, status, title) => {
        if(status) {
            this.props.store.setStore(title, value);
        }else {
            this.props.store.setStore(title, '');            
        }
    }


    render() {
        const {google} = this.props.store

        return (
            <div>
                <h4>Google</h4>
                <div style={{padding:"20px"}}>
                    {
                        google ? (
                        <WordCheckbox value={google.text} title="translation" handleChange={this.handleChanges} label={google.text} />
                        ): null
                    }
                </div>
                <h4>Possible Translations</h4>
                <div style={{padding:"20px"}}>
                    {
                    (typeof google.extra !== 'undefined' && google.extra.hasOwnProperty('possible-translations'))
                    ? google.extra['possible-translations']
                    .flat(3)
                    .filter(elm => typeof elm === 'string')
                    .filter(elm => elm !== this.props.store.selectedWord)
                    .map(item => (
                        <WordCheckbox handleChange={this.handleChanges} title="translation" value={item} label={item} />    
                    ))
                    : <p>No Possible Translation...</p>
                    
                    }
                </div>
                <h4>Arabic Pronunciation</h4>
                <div style={{padding:"20px"}}>
                    {
                    (typeof google.extra !== 'undefined' && google.extra.hasOwnProperty('translation'))
                    ? <WordCheckbox title="arabic_pronounciation" handleChange={this.ifContnetText} value={google.extra['translation'][1][2]} label={google.extra['translation'][1][2]} />    
                    : <p>No Possible Translation...</p>
                    
                    }
                </div>
                <h4>Yandex</h4>
                <div style={{padding:"20px"}}>
                    {
                    this.props.store.yandex !== null ?
                    <WordCheckbox handleChange={this.handleChanges} title="translation" value={this.props.store.yandex}  label={this.props.store.yandex} />
                    : <p>No Translation Found...</p>
                    }
                </div>
                <h4>Add Translation</h4>
                <div style={{padding:"20px"}}>
                    {this.state.translation.length > 0 
                        ? this.state.translation.map(item => (
                            <div key={Math.random()}>
                                <p>{item} <span><Icon name="trash" onClick={()=> this.removeTranslation(item)} /></span></p>
                            </div>
                        )): <p>Add your translation...</p> }
                <Form.InputGroup append={<Button
                        RootComponent="a"
                        color="primary"
                        onClick={this.addTranslation}
                    >Add
                    </Button>}>
                    <Form.Input value={this.state.input} onChange={(e) => this.setState({input: e.target.value})} placeholder="add translation..." />
                </Form.InputGroup>
                {this.state.err ? <p>{this.state.err}</p> : null}
                </div>
            </div>
        )
    }
}
