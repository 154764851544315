import { observable, computed, action, decorate, ObservableSet } from "mobx";
import api from '../utils/api';
import storage from "../utils/storage";



class EnglishStore {
    words = [];
    loading = false;
    selectedWord = "";
    search = [];
    family = [];
    word_details = []
    

    setSlectedWord(word) {this.selectedWord = word;}
    setLoading() {this.loading = true;}
    unsetLoading() {this.loading = false;}
    unsetSearch() {this.search = []}

    async getFamily(word) {
        this.setLoading()
        const response = await api.getEnglishWordFamily(word)
        this.family = response.data.words
        this.unsetLoading();
    }


    async getWordDetails(word) {
        this.setLoading()
        const response = await api.getEnglishWordDetails(word)
        this.word_details = response.data
        this.unsetLoading();
    }

    async getWords(count=0) {
        this.setLoading()
        const response = await api.getEnglishWords(storage.value())
        this.words = response.data.data;
        this.unsetLoading();
    }
    
    async useSearch(word) {
        try {
            const response = await api.getSearchResults(word);
            this.search = response.data.data;
        }
        catch(e) {
            this.unsetLoading()
        }
    }
}


decorate(EnglishStore, {
    words: observable,
    loading: observable,
    family: observable,
    search: observable,
    word_details: observable,
    getWords: action,
    getFamily: action,
    getWordDetails: action,
    useSearch:action,
    unsetSearch: action
  });

export default new EnglishStore();