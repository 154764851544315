import React, {Component} from 'react'
import auth from '../utils/storage';
import {AccountDropdown} from 'tabler-react'

export default class UserProfile extends Component {
  render() {
      const user = auth.user();
      const non_admin = [
        "profile", {
          icon: "settings",
          value: "Settings",
          to: "/settings"
        },
        {icon: 'ghost', to:"/login", value:"logout", onClick: ()=> {
            auth.logout();
        }}
      ]
      const admin = [
        "profile", {
          icon: "settings",
          value: "Settings",
          to: "/settings"
        },
        {
          icon: "lock",
          value: "Admin",
          to: "/admin"
        },
        {icon: 'ghost', to:"/login", value:"logout", onClick: ()=> {
            auth.logout();
        }},

      ]
    return (
      <div>
        {auth.isLoged
          ? (<AccountDropdown
            avatarURL={user.payload.avatar}
            name={user.payload.username}
            description="Gocognater"
            options={ user.payload.is_admin ? admin : non_admin }/>)
          : null
}
      </div>
    )
  }
}
