import React, { Component } from "react";
import api from "../utils/api";
export default class MoreDetails extends Component {
  state = { translations: "" };

  componentDidMount() {
    this.getInit();
  }

  getInit = async () => {
    const { word } = this.props.selected;
    const { data } = await api.getGoogle(
      word,
      this.props.lang ? this.props.lang : "en"
    );
    const translations = data.text;
    this.setState({ translations });
  };

  render() {
    return <div>{this.state.translations}</div>;
  }
}
