import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function CheckboxList(props) {

    const handleCheck = (e) => {
        if (props.handleExampleChange) {
          return props.handleChange(e.target.value, e.target.checked, e);
        }
        return ''
    }

    const classes = useStyles();
    const [checked, setChecked] = React.useState([0]);

    const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

    const {examples, title} = props;
  return (
    <List className={classes.root}>
        <h4>{title !== null ? title : null}</h4>
      {(examples !== null && examples.length > 1) ? Object.keys(examples).map(value => {
        const labelId = `checkbox-list-label-${examples[value]}`;

        return (
          <ListItem key={labelId} role={undefined} dense button onClick={handleToggle(examples[value])}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                tabIndex={-1}
                disableRipple
                value={examples[value]}
                onChange={handleCheck}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={examples[value]} />
          </ListItem>
        );
      }): <p>No results found...</p>}
    </List>
  );
}
